import React, { FC } from "react";
import Heading from "components/Heading/Heading";

export interface Statistic {
  id: string;
  heading: string;
  subHeading: string;
}

const FOUNDER_DEMO: Statistic[] = [
  {
    id: "1",
    heading: "Online Booking",
    subHeading: "Made Easy booking No need your credit card to booking",
  },
  {
    id: "2",
    heading: "Flat Price",
    subHeading: "Our Rate is always flat, no confuse based on destination",
  },
  {
    id: "3",
    heading: "Private Drivers",
    subHeading:
      "We have trained and friendly driver pool with polite",
  },
  {
    id: "4",
    heading: "Free waiting",
    subHeading: "Our driver will be stayed when your arrival to the Airport",
  },
  {
    id: "5",
    heading: "Registered Taxi",
    subHeading: "We are registered and government approved taxi firm",
  }
];

export interface SectionStatisticProps {
  className?: string;
}

const SectionStatistic: FC<SectionStatisticProps> = ({ className = "" }) => {
  return (
    <div className={`nc-SectionStatistic relative ${className}`}>
      <div className="grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {FOUNDER_DEMO.map((item) => (
          <div
            key={item.id}
            className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800"
          >
            <h2 className="text-xl font-semibold leading-none text-neutral-900 dark:text-neutral-200">
              {item.heading}
            </h2>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              {item.subHeading}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionStatistic;
