import React, { FC } from 'react';
import Heading from 'components/Heading/Heading';
import { DEMO_POSTS } from 'data/things';
import { PostDataType } from 'data/types';
import Pagination from 'shared/Pagination/Pagination';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import WidgetTags from './WidgetTags';
import WidgetCategories from './WidgetCategories';
import WidgetPosts from './WidgetPosts';
import Card3 from './Card3';
import { Link } from 'react-router-dom';

// THIS IS DEMO FOR MAIN DEMO
// OTHER DEMO WILL PASS PROPS
const postsDemo: PostDataType[] = DEMO_POSTS.filter((_, i) => i < 3);
//
export interface SectionLatestPostsProps {
  posts?: PostDataType[];
  className?: string;
  postCardName?: 'card3';
}

const SectionLatestPosts: FC<SectionLatestPostsProps> = ({
  posts = postsDemo,
  postCardName = 'card3',
  className = '',
}) => {
  const renderCard = (post: PostDataType) => {
    switch (postCardName) {
      case 'card3':
        return <Card3 key={post.id} className="" post={post} />;

      default:
        return null;
    }
  };

  return (
    <div className={`nc-SectionLatestPosts relative ${className}`}>
      <div className="container flex flex-col lg:flex-row">
        <div className="w-full">
          <Heading>Things to do in disneyland paris 🎈</Heading>
          <div className={`grid gap-6 md:gap-8 grid-cols-1`}>
            {posts.map((post) => renderCard(post))}
          </div>
          <div className="flex flex-col mt-12 md:mt-20 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <Link to="/things-to-do">
              <ButtonPrimary>Show me more</ButtonPrimary>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionLatestPosts;
