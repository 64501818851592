import { Tab } from '@headlessui/react';
import React, { FC, Fragment, useState, useEffect } from 'react';
import Label from 'components/Label/Label';
import Input from 'shared/Input/Input';
import Textarea from 'shared/Textarea/Textarea';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Button, notification, Spin } from 'antd';
import axios from 'axios';
import moment from 'moment';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import dayjs from 'dayjs';
import { TimePicker } from 'antd';
import type { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = '',
}) => {
  const format = 'HH:mm';
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [price, setPrice] = useState(0);
  const [priceLoading, setPriceLoading] = useState(false);

  const [promotionCode, setPromotionCode] = useState<any>(null);
  const [isPromoDataLoading, setIsPromoDataLoading] = useState(false);
  const [offPercentage, setOffPercentage] = useState(0);
  const [addPromoDisabled, setAddPromoDisabled] = useState(false);

  const pickup = searchParams.get('pickup');
  const dropOff = searchParams.get('dropOff');
  const totalGuests = searchParams.get('totalGuests');
  const babySeats = searchParams.get('babySeats');
  const babyBoosters = searchParams.get('babyBoosters');
  const vehicleType = searchParams.get('vehicleType');
  const dropOffLocationType = searchParams.get('dropOffLocationType');
  const pickupLocationType = searchParams.get('pickupLocationType');
  const droppingLocationType = searchParams.get('droppingLocationType');
  const pickupLocationAddress = searchParams.get('pickupLocationAddress');
  const droppingLocationAddress = searchParams.get('droppingLocationAddress');

  // form data
  const [name, setName] = useState<any>(null);
  const [email, setEmail] = useState<any>(null);
  const [phoneNumber, setPhoneNumber] = useState<any>(null);
  const [pickupDate, setPickupDate] = useState<any>(null);
  const [pickupTime, setPickupTime] = useState<any>(null);
  const [returnDate, setReturnDate] = useState<any>(null);
  const [returnTime, setReturnTime] = useState<any>(null);
  const [comments, setComments] = useState<any>(null);
  const [flightNumber, setFlightNumber] = useState<any>(null);
  const [trainNumber, setTrainNumber] = useState<any>(null);
  const [flightOrigin, setFlightOrigin] = useState<any>(null);
  const [trainOrigin, setTrainOrigin] = useState<any>(null);
  // const [pickupLocationAddress, setPickupLocationAddress] = useState<any>(null);
  // const [droppingLocationAddress, setDroppingLocationAddress] =
  //   useState<any>(null);

  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const onChangePickupTime = (time: Dayjs | null, timeString: string) => {
    setPickupTime(timeString);
  };

  const onChangeReturnPickupTime = (time: Dayjs | null, timeString: string) => {
    setReturnTime(timeString);
  };

  useEffect(() => {
    if (pickup && dropOff && totalGuests && dropOffLocationType) {
      fetchPrice();
    } else {
      setPrice(0);
    }
  }, [pickup, dropOff, totalGuests, dropOffLocationType]);

  const fetchPrice = async () => {
    try {
      setPriceLoading(true);
      let config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_BASE_URL}/user/getprice/${pickup}/${dropOff}/${dropOffLocationType}/${totalGuests}/${vehicleType}`,
      };

      const response = await axios(config);
      const price = response.data.price;
      if (price) {
        setPrice(price);
      } else {
        setPrice(0);
        notification['error']({
          key: 'price_fetch',
          message: 'Sorry',
          description: 'Currently no prices have added for this trip!',
        });
      }
      setPriceLoading(false);
    } catch (error) {
      setPriceLoading(false);
      notification['error']({
        message: 'Oops!',
        description: 'Error when fetching price!',
      });
    }
  };

  const fetchPromoDetails = async () => {
    try {
      setIsPromoDataLoading(true);
      let config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_BASE_URL}/admin/getdiscount/${promotionCode}`,
      };

      const response = await axios(config);
      const percentage = response.data.OffPercentage;
      if (percentage) {
        setOffPercentage(percentage);
        const newPrice = price - (price * percentage) / 100;
        setPrice(newPrice);
        setAddPromoDisabled(true);
      } else {
        setOffPercentage(0);
        notification['error']({
          key: 'promotion_fetch',
          message: 'Sorry',
          description: 'Code not found!',
        });
      }
      setIsPromoDataLoading(false);
    } catch (error) {
      setIsPromoDataLoading(false);
      notification['error']({
        key: 'promotion_fetch',
        message: 'Sorry!',
        description: 'Cannot use this Promotion Code!',
      });
    }
  };

  const handleConfirm = async () => {
    try {
      setIsConfirmLoading(true);

      const payload = {
        pickup: pickup,
        Destination: dropOff,
        type: dropOffLocationType,
        Guests: Number(totalGuests),
        Price: Number(price),
        Name: name,
        Email: email,
        pickupDate: pickupDate,
        pickupTime: moment(pickupTime, 'hh:mm').format('LT'),
        Comment: comments,
        PromoCode: promotionCode,
        Phonenumber: phoneNumber,
        pickupLocationType: pickupLocationType,
        droppingLocationType: droppingLocationType,
        flightNumber: flightNumber,
        trainNumber: trainNumber,
        flightOrigin: flightOrigin,
        trainOrigin: trainOrigin,
        pickupLocationAddress: pickupLocationAddress,
        droppingLocationAddress: droppingLocationAddress,
        vehicleType: vehicleType,
        babyseat: Number(babySeats),
        babyboost: Number(babyBoosters),
        returnTime: returnTime
          ? moment(returnTime, 'hh:mm').format('LT')
          : null,
        returnDate: returnDate ? returnDate : null,
      };

      if (
        !pickup ||
        !dropOff ||
        !dropOffLocationType ||
        !totalGuests ||
        !price ||
        !name ||
        !email ||
        !pickupDate ||
        !pickupTime ||
        !phoneNumber ||
        !vehicleType
      ) {
        setIsConfirmLoading(false);
        return notification['warning']({
          key: 'add_booking',
          message: 'Sorry!',
          description: 'All required fileds must filled before add booking!',
        });
      }

      if (
        pickupLocationType === 'Airports' &&
        (!flightNumber || !flightOrigin)
      ) {
        setIsConfirmLoading(false);
        return notification['warning']({
          key: 'add_booking',
          message: 'Sorry!',
          description:
            'All required fileds about flight must filled before add booking!',
        });
      }

      if (
        pickupLocationType === 'Train_Stations' &&
        (!trainNumber || !trainOrigin)
      ) {
        setIsConfirmLoading(false);
        return notification['warning']({
          key: 'add_booking',
          message: 'Sorry!',
          description:
            'All required fileds about train must filled before add booking!',
        });
      }

      if (dropOffLocationType === 'Two' && (!returnDate || !returnTime)) {
        setIsConfirmLoading(false);
        return notification['warning']({
          key: 'add_booking',
          message: 'Sorry!',
          description:
            'All required fileds about return must filled before add booking!',
        });
      }

      // if (pickupLocationType === 'Other_Location' && !pickupLocationAddress) {
      //   setIsConfirmLoading(false);
      //   return notification['warning']({
      //     key: 'add_booking',
      //     message: 'Sorry!',
      //     description: 'Pickup Address is missing!',
      //   });
      // }

      // if (droppingLocationType === 'Other_Location' && !droppingLocationAddress) {
      //   setIsConfirmLoading(false);
      //   return notification['warning']({
      //     key: 'add_booking',
      //     message: 'Sorry!',
      //     description: 'Destination Address is missing!',
      //   });
      // }

      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_BASE_URL}/user/addbooking`,
        data: payload,
      };

      const response = await axios(config);
      if (response.status === 201) {
        navigate(
          `/pay-done?pickup=${pickup}&dropOff=${dropOff}&totalGuests=${totalGuests}&dropOffLocationType=${dropOffLocationType}&reference=${response.data.Reference}&pickupDate=${pickupDate}&pickupTime=${pickupTime}&name=${name}&price=${price}&babySeats=${babySeats}&babyBoosters=${babyBoosters}&vehicleType=${vehicleType}&pickupLocationAddress=${pickupLocationAddress}&droppingLocationAddress=${droppingLocationAddress}&returnDate=${returnDate}&returnTime=${returnTime}&comments=${comments}&email=${email}&phoneNumber=${phoneNumber}`
        );
      }
    } catch (error) {
      setIsConfirmLoading(false);
      console.log(error);
      notification['error']({
        key: 'add_booking',
        message: 'Sorry!',
        description:
          'Error occured when adding the booking. Please try again later!',
      });
    }
  };

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 mb-5 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Booking Price</h3>
          <div className="space-y-1">
            <Label>Enter Promotion Code Here </Label>
            <Input
              placeholder="SUMMER23"
              value={promotionCode}
              onChange={(e) => setPromotionCode(e.target.value)}
            />
          </div>

          <Button
            size="large"
            type="primary"
            className="bg-primary-500"
            onClick={() => fetchPromoDetails()}
            loading={isPromoDataLoading}
            style={{ borderRadius: '20px' }}
            disabled={addPromoDisabled}
          >
            Add Promo
          </Button>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>
              {priceLoading ? <Spin size="small" /> : <span>{price} €</span>}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm Your Booking
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold">Your trip</h3>
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex space-x-4">
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">
                  Pickup Location
                </span>
                <span className="mt-1.5 text-lg font-semibold">{pickup}</span>
              </div>
            </div>
            <div className="flex-1 p-5 flex space-x-4">
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">
                  Dropoff Location
                </span>
                <span className="mt-1.5 text-lg font-semibold">{dropOff}</span>
              </div>
            </div>
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex space-x-4">
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Trip Type</span>
                <span className="mt-1.5 text-lg font-semibold">
                  {dropOffLocationType === 'One' ? 'One Way' : 'Two Way'}
                </span>
              </div>
            </div>
            <div className="flex-1 p-5 flex space-x-4">
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Guests</span>
                <span className="mt-1.5 text-lg font-semibold">
                  {totalGuests} Guests
                </span>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold">Booking Details</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <span className="text-slate-500 text-sm">
            (required fields are marked with *)
          </span>

          <div className="mt-6">
            <Tab.Group>
              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>
                      Name <span className="text-red-600">*</span>
                    </Label>
                    <Input
                      placeholder="JOHN DOE"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="space-y-1">
                    <Label>
                      Email <span className="text-red-600">*</span>
                    </Label>
                    <Input
                      type="email"
                      placeholder="john@gmail.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="space-y-1">
                    <Label>
                      Phone Number <span className="text-red-600">*</span>
                    </Label>
                    {/* <Input
                      placeholder="+33 1234 5678"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    /> */}
                    <PhoneInput
                      placeholder="+33 1234 5678"
                      international
                      defaultCountry="FR"
                      withCountryCallingCode
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                    />
                  </div>

                  <div className="flex space-x-5">
                    <div className="flex-1 space-y-1">
                      <Label>
                        Pickup Date <span className="text-red-600">*</span>
                      </Label>
                      <Input
                        type="date"
                        value={pickupDate}
                        onChange={(e) => setPickupDate(e.target.value)}
                        className="datepicker"
                      />
                    </div>
                    <div className="flex-1 space-y-1">
                      <Label>
                        Pickup Time <span className="text-red-600">*</span>
                      </Label>
                      {/* <Input
                        type="time"
                        value={pickupTime}
                        onChange={(e) => setPickupTime(e.target.value)}
                      /> */}
                      <div>
                        <TimePicker
                          onChange={onChangePickupTime}
                          size="large"
                          className="w-full rounded-[15px] border border-neutral-200 dark:border-neutral-700"
                          format={format}
                        />
                      </div>
                      <div>
                        <span className="text-xs text-red-600">
                          Night time charge will be added between (22:00 to
                          05:59)
                        </span>
                      </div>
                    </div>
                  </div>

                  {dropOffLocationType === 'Two' && (
                    <div className="flex space-x-5">
                      <div className="flex-1 space-y-1">
                        <Label>
                          Return Date <span className="text-red-600">*</span>
                        </Label>
                        <Input
                          type="date"
                          value={returnDate}
                          onChange={(e) => setReturnDate(e.target.value)}
                          className="datepicker"
                        />
                      </div>
                      <div className="flex-1 space-y-1">
                        <Label>
                          Return Pickup Time
                          <span className="text-red-600">*</span>
                        </Label>
                        {/* <Input
                          type="time"
                          value={returnTime}
                          onChange={(e) => setReturnTime(e.target.value)}
                        /> */}
                        <div>
                          <TimePicker
                            onChange={onChangeReturnPickupTime}
                            size="large"
                            className="w-full rounded-[15px] border border-neutral-200 dark:border-neutral-700"
                            format={format}
                          />
                        </div>
                        <div>
                          <span className="text-xs text-red-600">
                            Night time charge will be added between (22:00 to
                            05:59)
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  {pickupLocationType === 'Airports' && (
                    <div className="flex space-x-5">
                      <div className="flex-1 space-y-1">
                        <Label>
                          Flight Number<span className="text-red-600">*</span>
                        </Label>
                        <Input
                          placeholder="AA123"
                          value={flightNumber}
                          onChange={(e) => setFlightNumber(e.target.value)}
                        />
                      </div>

                      <div className="flex-1 space-y-1">
                        <Label>
                          Flight Origin<span className="text-red-600">*</span>
                        </Label>
                        <Input
                          placeholder="Paris Charles de Gaulle Airport (CDG)"
                          value={flightOrigin}
                          onChange={(e) => setFlightOrigin(e.target.value)}
                        />
                      </div>
                    </div>
                  )}

                  {pickupLocationType === 'Train_Stations' && (
                    <div className="flex space-x-5">
                      <div className="flex-1 space-y-1">
                        <Label>
                          Train Number<span className="text-red-600">*</span>
                        </Label>
                        <Input
                          placeholder="12345"
                          value={trainNumber}
                          onChange={(e) => setTrainNumber(e.target.value)}
                        />
                      </div>

                      <div className="flex-1 space-y-1">
                        <Label>
                          Train Origin<span className="text-red-600">*</span>
                        </Label>
                        <Input
                          placeholder="12345"
                          value={trainOrigin}
                          onChange={(e) => setTrainOrigin(e.target.value)}
                        />
                      </div>
                    </div>
                  )}

                  {pickupLocationAddress !== 'null' && (
                    <div className="space-y-1">
                      <Label>Pickup Address</Label>
                      <Input
                        value={
                          pickupLocationAddress !== null
                            ? pickupLocationAddress
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  )}

                  {droppingLocationAddress !== 'null' && (
                    <div className="space-y-1">
                      <Label>Destination Address</Label>
                      <Input
                        value={
                          droppingLocationAddress !== null
                            ? droppingLocationAddress
                            : ''
                        }
                        readOnly
                      />
                    </div>
                  )}

                  <div className="space-y-1">
                    <Label>Comments</Label>
                    <Textarea
                      placeholder="..."
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                    />
                    <span className="text-sm text-neutral-500 block">
                      Write a special comment for the booking
                    </span>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            <div className="pt-8">
              <Button
                size="large"
                type="primary"
                className="bg-primary-500"
                onClick={() => handleConfirm()}
                style={{ borderRadius: '20px' }}
                loading={isConfirmLoading}
              >
                Confirm Booking
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPagePageMain;
