import React, { FC } from "react";
import Heading from "components/Heading/Heading";

export interface Statistic {
  id: string;
  heading: string;
  subHeading: string;
}

const FOUNDER_DEMO: Statistic[] = [
  {
    id: "1",
    heading: "8 Hours Paris Day Tour 450 €",
    subHeading:
      "Do you want to Take in the breathtaking views from the top and capture memorable photos in Exploring Parisian Gems.",
  },
  {
    id: "2",
    heading: "4 Hours Paris Day Tour 250 €",
    subHeading: "We have carefully designed an itinerary which covers the must-see locations in 4 hours, while your busy schedule.",
  },
  {
    id: "3",
    heading: "4 Hours Night Tour 300  €",
    subHeading:
      "Do you know why we want to do a night tour in Paris? It's because Paris is a city that becomes even more magical and enchanting when the sun goes down!",
  },
  {
    id: "4",
    heading: "Business Tour",
    subHeading: "We have clean and neat Business tour for your all meeting , incentive convention and exhibition activities in France",
  },
];

export interface SectionStatisticProps {
  className?: string;
}

const SectionStatistic2: FC<SectionStatisticProps> = ({ className = "" }) => {
  return (
    <div className={`nc-SectionStatistic relative ${className}`}>
      <Heading
        desc="Paris Top Tours & Activities are most important, wolrd famous destination"
      >
        🚖 Day Tours
      </Heading>
      <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-4 xl:gap-8">
        {FOUNDER_DEMO.map((item) => (
          <div
            key={item.id}
            className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800"
          >
            <h3 className="text-lg font-semibold leading-none text-neutral-900 md:text-lg dark:text-neutral-200">
              {item.heading}
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              {item.subHeading}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionStatistic2;
