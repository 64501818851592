import React, { FC, ReactNode } from 'react';
import imagePng from 'images/hero-right2.png';
import HeroSearchForm, {
  SearchTab,
} from 'components/HeroSearchForm/HeroSearchForm';
import SectionGridFilterCard from 'containers/ListingCarPage/SectionGridFilterCard';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import SectionStatistic from 'containers/PageAbout/SectionStatistic';
import Image1 from '../../images/slider/1.png';
import Image2 from '../../images/slider/2.png';
import Image3 from '../../images/slider/3.jpg';
import Image4 from '../../images/slider/4.jpeg';
import Image5 from '../../images/slider/5.jpeg';

export interface SectionHeroArchivePageProps {
  className?: string;
  listingType?: ReactNode;
  currentPage: 'Stays' | 'Experiences' | 'Cars' | 'Flights';
  currentTab: SearchTab;
  rightImage?: string;
}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
  className = '',
  listingType,
  currentPage,
  currentTab,
  rightImage = imagePng,
}) => {
  return (
    <div
      className={`nc-SectionHeroArchivePage flex flex-col relative ${className}`}
      data-nc-id="SectionHeroArchivePage"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start pb-14 lg:-mt-[20px] lg:pb-64 xl:pb-80 xl:pr-14 lg:mr-10 xl:mr-0">
          <HeroSearchForm currentPage={currentPage} currentTab={currentTab} />
        </div>
        <div className="flex-grow lg:-mt-[285px] hidden lg:block">
          {/* <img className="w-full rounded-md" src={rightImage} alt="hero" /> */}
          <Carousel showThumbs={false} autoPlay infiniteLoop>
            <div>
              <img className="w-full rounded-md" src={Image1} alt="hero" />
            </div>
            <div>
              <img className="w-full rounded-md" src={Image2} alt="hero" />
            </div>
            <div>
              <img className="w-full rounded-md" src={Image3} alt="hero" />
            </div>
            <div>
              <img className="w-full rounded-md" src={Image4} alt="hero" />
            </div>
            <div>
              <img className="w-full rounded-md" src={Image5} alt="hero" />
            </div>
          </Carousel>
        </div>
      </div>

      <div className="-mt-10 lg:-mt-[200px]">
        <SectionStatistic />
        {/* <SectionGridFilterCard /> */}
      </div>
    </div>
  );
};

export default SectionHeroArchivePage;
