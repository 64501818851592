import React, { FC } from "react";
import Heading from "components/Heading/Heading";

export interface Statistic {
  id: string;
  heading: string;
  subHeading: string;
}

const FOUNDER_DEMO: Statistic[] = [
  {
    id: "1",
    heading: "Service",
    subHeading:
      "We prioritize providing top-notch service to our clients, ensuring a comfortable and luxurious experience.",
  },
  {
    id: "2",
    heading: "Quality",
    subHeading: "We offer the best prices in the market without compromising on the quality of our service.",
  },
  {
    id: "3",
    heading: "Satisfaction",
    subHeading:
      "We understand the importance of quick communication and strive to respond promptly to all inquiries and requests.",
  }
];

export interface SectionStatisticProps {
  className?: string;
}

const SectionStatistic3: FC<SectionStatisticProps> = ({ className = "" }) => {
  return (
    <div className={`nc-SectionStatistic relative ${className}`}>
      <Heading
        desc=""
      >
        🤝 Our Values
      </Heading>
      <div className="grid md:grid-cols-1 gap-6 lg:grid-cols-3 xl:gap-8">
        {FOUNDER_DEMO.map((item) => (
          <div
            key={item.id}
            className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800"
          >
            <h3 className="text-lg font-semibold leading-none text-neutral-900 md:text-lg dark:text-neutral-200">
              {item.heading}
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              {item.subHeading}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionStatistic3;
