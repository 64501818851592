import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { Spin, Select, Typography, Table, notification, Card } from 'antd';
import Card3Small from 'containers/BlogPage/Card3Small';
import { PostDataType } from 'data/types';
import { DEMO_POSTS } from 'data/things';
import Card3 from 'containers/BlogPage/Card3';
import Card12 from 'containers/BlogPage/Card12';
import Card13 from 'containers/BlogPage/Card13';
import { Helmet } from 'react-helmet';

export interface SectionLatestPostsProps {
  posts?: PostDataType[];
  className?: string;
  postCardName?: 'card3';
}

const postsDemo: PostDataType[] = DEMO_POSTS.filter((_, i) => i < 16);

const PageThingsToDo: FC<SectionLatestPostsProps> = ({
  posts = postsDemo,
  postCardName = 'card3',
  className = '',
}) => {
  const renderCard = (post: PostDataType) => {
    switch (postCardName) {
      case 'card3':
        return <Card3 key={post.id} className="" post={post} />;

      default:
        return null;
    }
  };

  return (
    <div className={`nc-PageLogin`} data-nc-id="PageLogin">
      <Helmet>
        <title>Things-to-do | Mydisney Private airport transfers</title>
        <meta
          name="title"
          content="Enhancing Your Travel Experience with Mydisney Private Airport Transfers"
        />
        <meta
          name="description"
          content="Elevate your Disneyland experience with Mydisney Private Airport Transfers. Explore day tours and a variety of activities to make your trip unforgettable"
        />
      </Helmet>
      <div className="container mb-24 lg:mb-32 lg:px-20">
        <h2 className="my-10 flex items-center text-xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Things to do in disneyland paris 🎈
        </h2>

        <p className='my-8'>
          Discover the enchanting allure of Paris, affectionately known as the
          City of Lights, where romance and history intertwine seamlessly. From
          the majestic Eiffel Tower to the captivating art district of
          Montmartre, Paris offers a plethora of attractions that captivate the
          hearts of visitors. Immerse yourself in the rich culture and history
          at the Louvre Museum or wander through the opulent halls of the Palace
          of Versailles. Experience the vibrant nightlife at the iconic Moulin
          Rouge or explore the mysterious catacombs beneath the city streets.
          With its iconic landmarks and charming neighborhoods, Paris promises
          an unforgettable journey filled with beauty and romance.
        </p>
        <div
          className={`nc-WidgetPosts rounded-3xl overflow-hidden ${className} px-0 lg:px-20`}
          data-nc-id="WidgetPosts"
        >
          <div className={`grid gap-6 md:gap-12 grid-cols-1`}>
            {posts.map((post) => renderCard(post))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageThingsToDo;
