import { FC } from 'react';
import { Helmet } from 'react-helmet';

const TAndC: FC = () => {
  return (
    <div className={`nc-PageLogin`} data-nc-id="PageLogin">
      <Helmet>
        <title>Terms & Conditions | MyDisneyTransfer</title>
        <meta name="title" content="Discover Terms & Conditions" />
        <meta name="description" content="Discover Terms & Conditions" />
      </Helmet>
      <div className="container mb-24 lg:mb-32 min-h-screen">
        <h2 className="my-10 flex items-center text-xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Terms & Conditions
        </h2>
        <div>
          <p>
            We, MyDisneyTransfer(Airport Disney Transfer), a company registered
            in France with company number 901784785 Boigany and registered
            office address of 14 Rue Jean, Missout 93430, Villetaneuse, France,
            trading as MyDisneyTransfer, act solely business entitle for all
            bookings made on behalf of our customers.
          </p>

          <p className="mt-3">
            For all transportation arrangements, your contract will be with the
            supplier of the arrangements, referred to as the
            "Supplier/Principal."
          </p>

          <p className="mt-3">
            By booking with us, the primary person listed on the booking agrees
            to the following:
          </p>
        </div>

        <div className="mt-5">
          <ul className='list-disc'>
            <li>Acknowledge and accept our Agency Terms & Conditions.</li>
            <li>
              Consent to our use of personal data as outlined in our Privacy
              Policy & GDRP
            </li>
            <li>
              Confirm authorization to disclose personal details for all
              individuals listed on the booking.
            </li>
            <li>Confirm being over 18 years of age.</li>
            <li>
              Accept financial responsibility for payment on behalf of all
              individuals listed on the booking.
            </li>
          </ul>
        </div>

        <div className="mt-5">
          <ol className='list-decimal'>
            <li>
              Contract Your contract for transportation arrangements will be
              with the Supplier/Principal specified on your e-ticket. We act as
              an agent and hold no responsibility for the services provided by
              the Supplier/Principal.
            </li>

            <li>
              Liability We do not accept liability for any contracts entered
              into or services provided by any supplier or other party connected
              with the arrangements.
            </li>

            <li>
              Booking You are responsible for ensuring all booking details
              are correct. Any changes may incur charges. Information provided
              will only be shared with relevant suppliers and may be passed to
              authorities or as required by law.
            </li>

            <li>
              Payment Full payment is required at the end of transfer, unless
              otherwise agreed with the Supplier/Principal.
            </li>

            <li>
              Prices Prices are subject to change and errors may occur. It is
              your responsibility to verify the price at the time of booking.
            </li>

            <li>
              Special Requests While we will endeavor to fulfill special
              requests, we cannot guarantee their availability and will not be
              liable if they cannot be met.
            </li>

            <li>
              Changes and Cancellations by You Cancellation policies vary by
              Supplier/Principal and will be outlined in your eTicket. Any
              changes or cancellations must be made through direct phone call or
              email request.
            </li>

            <li>
              Changes and Cancellations by the Supplier/Principal You will be
              notified of any significant changes or cancellations by the
              Supplier/Principal. We will assist in arranging alternative
              options.
            </li>

            <li>
              Our Responsibility Our role is limited to making bookings as
              per your instructions. We accept no responsibility for the
              provision of services.
            </li>

            <li>
              Complaints Complaints regarding transportation services should
              be directed to the Supplier/Principal. Complaints about our
              booking service can be submitted directly to us.
            </li>

            <li>
              The reviews published on the our website or relevant collected
              through the SICILY ACTION or page, to whose policy reference
              should be made to better understand the origin of the reviews and
              the activities that we can and cannot carry out on them.
            </li>

            <li>
              Law and Jurisdiction These terms are governed by French law,
              with exclusive jurisdiction in the courts of Paris.
            </li>

            <li>
              Vehicle Descriptions and Standards Vehicle descriptions are
              provided by the Supplier/Principal and may vary. We cannot
              guarantee accuracy.
            </li>

            <li>
              Journey Times Trip times are estimates and may vary due to
              external factors.
            </li>

            <li>
              Documentation & Information Descriptions provided are intended
              as a general overview. For specific details, please contact us.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default TAndC;
