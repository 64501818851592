import { FC, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Typography, Table, notification } from 'antd';
import type { TableColumnType } from 'antd';
import { Button, Input } from 'antd';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import { AiOutlineSearch } from 'react-icons/ai';
import { Helmet } from 'react-helmet';

const PagePricing: FC = () => {
  const { Text } = Typography;
  const [loading, setLoading] = useState<boolean>(false);
  const [pricesData, setPricesData] = useState<any>([]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<any>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: any
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex: any): TableColumnType<any> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <div className="flex gap-3">
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<AiOutlineSearch />}
            size="small"
            className="bg-primary-500"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            size="small"
            className="flex-grow"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <AiOutlineSearch style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    fetchPrices();
  }, []);

  const fetchPrices = async () => {
    try {
      setLoading(true);
      let config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_BASE_URL}/admin/viewprice`,
      };
      const response = await axios(config);
      const prices = response.data;
      setPricesData(prices);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification['error']({
        message: 'Error',
        description: 'Error while fetching prices!',
      });
    }
  };

  const columns: any = [
    {
      title: 'Pickup',
      dataIndex: 'Pickup',
      key: 'Pickup',
      render: (row: any) => (
        <Text className="whitespace-nowrap">{row.Pickup}</Text>
      ),
      ...getColumnSearchProps('Pickup'),
    },
    {
      title: 'Destination',
      dataIndex: 'Destination',
      key: 'Destination',
      render: (row: any) => (
        <Text className="whitespace-nowrap">{row.Destination}</Text>
      ),
      ...getColumnSearchProps('Destination'),
    },
    {
      title: 'Vehicle Type',
      dataIndex: 'VehicleType',
      key: 'VehicleType',
      render: (row: any) => (
        <Text className="whitespace-nowrap">{row.VehicleType}</Text>
      ),
      ...getColumnSearchProps('VehicleType'),
    },
    {
      title: 'One Way',
      children: [
        {
          title: <span className="whitespace-nowrap">3 Pax</span>,
          key: '_id',
          render: (row: any) => (
            <Text className="whitespace-nowrap">
              {row.One_Three_Pack
                ? `${row.One_Three_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="whitespace-nowrap">4 Pax</span>,
          key: '_id',
          render: (row: any) => (
            <Text className="whitespace-nowrap">
              {row.One_Four_Pack
                ? `${row.One_Four_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="whitespace-nowrap">5 Pax</span>,
          key: '_id',
          render: (row: any) => (
            <Text className="whitespace-nowrap">
              {row.One_Five_Pack
                ? `${row.One_Five_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="whitespace-nowrap">6 Pax</span>,
          key: '_id',
          render: (row: any) => (
            <Text className="whitespace-nowrap">
              {row.One_Six_Pack
                ? `${row.One_Six_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="whitespace-nowrap">7 Pax</span>,
          key: '_id',
          render: (row: any) => (
            <Text className="whitespace-nowrap">
              {row.One_Seven_Pack
                ? `${row.One_Seven_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="whitespace-nowrap">8 Pax</span>,
          key: '_id',
          render: (row: any) => (
            <Text className="whitespace-nowrap">
              {row.One_Eight_Pack
                ? `${row.One_Eight_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
      ],
    },
    {
      title: 'Two Way',
      children: [
        {
          title: <span className="whitespace-nowrap">3 Pax</span>,
          key: '_id',
          render: (row: any) => (
            <Text className="whitespace-nowrap">
              {row.Two_Three_Pack
                ? `${row.Two_Three_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="whitespace-nowrap">4 Pax</span>,
          key: '_id',
          render: (row: any) => (
            <Text className="whitespace-nowrap">
              {row.Two_Four_Pack
                ? `${row.Two_Four_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="whitespace-nowrap">5 Pax</span>,
          key: '_id',
          render: (row: any) => (
            <Text className="whitespace-nowrap">
              {row.Two_Five_Pack
                ? `${row.Two_Five_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="whitespace-nowrap">6 Pax</span>,
          key: '_id',
          render: (row: any) => (
            <Text className="whitespace-nowrap">
              {row.Two_Six_Pack
                ? `${row.Two_Six_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="whitespace-nowrap">7 Pax</span>,
          key: '_id',
          render: (row: any) => (
            <Text className="whitespace-nowrap">
              {row.Two_Seven_Pack
                ? `${row.Two_Seven_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
        {
          title: <span className="whitespace-nowrap">8 Pax</span>,
          key: '_id',
          render: (row: any) => (
            <Text className="whitespace-nowrap">
              {row.Two_Eight_Pack
                ? `${row.Two_Eight_Pack} ${process.env.REACT_APP_CURRENCY}`
                : '-'}
            </Text>
          ),
        },
      ],
    },
  ];

  return (
    <div className={`nc-PageLogin`} data-nc-id="PageLogin">
      <Helmet>
        <title>Price information | MyDisneyTransfer</title>
        <meta
          name="title"
          content="Look the price how our service support for your comfortable journey"
        />
        <meta
          name="description"
          content="disneyland paris competitive airport transfer taxi service prices that fit your budget. Compare our rates and book your ride today"
        />
      </Helmet>
      <div className="container mb-24 lg:mb-32 min-h-screen">
        <h2 className="my-20 flex items-center text-xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Price list
        </h2>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <Table
              dataSource={pricesData}
              loading={loading}
              rowKey="_id"
              pagination={{
                position: ['bottomCenter', 'bottomCenter'],
              }}
              columns={columns}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagePricing;
