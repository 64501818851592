import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { Spin, Select, Typography, Table, notification, Card } from 'antd';
import Card3Small from 'containers/BlogPage/Card3Small';
import { PostDataType } from 'data/types';
import { DEMO_POSTS } from 'data/posts';
import { Helmet } from "react-helmet";

export interface WidgetPostsProps {
  className?: string;
  posts?: PostDataType[];
}

const widgetPostsDemo: PostDataType[] = DEMO_POSTS.filter((_, i) => i < 10);

const PageFaq: FC<WidgetPostsProps> = ({
  className = 'bg-neutral-100 dark:bg-neutral-800',
  posts = widgetPostsDemo,
}) => {
  return (
    <div className={`nc-PageLogin`} data-nc-id="PageLogin">
      <Helmet>
        <title>Frequently Asked Questions | MyDisneyTransfer</title>
        <meta name="title" content="Discover the frequently answer question to know our service before your reservation" />
        <meta name="description" content="Explore our frequently asked questions to learn more about MyDisneyTransfer's services before making your reservation." />
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          FAQ
        </h2>
        <div
          className={`nc-WidgetPosts rounded-3xl overflow-hidden ${className}`}
          data-nc-id="WidgetPosts"
        >
          <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
            {posts.map((post) => (
              <Card3Small
                className="p-4 xl:px-5 xl:py-6 hover:bg-neutral-200 dark:hover:bg-neutral-700"
                key={post.id}
                post={post}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageFaq;
