import { FC } from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy: FC = () => {

  return (
    <div className={`nc-PageLogin`} data-nc-id="PageLogin">
      <Helmet>
        <title>Privacy Policy | MyDisneyTransfer</title>
        <meta name="title" content="Discover Privacy Policy" />
        <meta name="description" content="Discover Privacy Policy" />
      </Helmet>
      <div className="container mb-24 lg:mb-32 min-h-screen">
        <h2 className="my-10 flex items-center text-xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Privacy Policy
        </h2>
        <div>
          <h3 className="text-lg font-semibold mb-3 underline">Introduction</h3>

          <p>
            The purpose of this privacy policy is to inform users of our site
            about the personal data we collect and how we use it. This policy
            also outlines the rights of users and our data protection measures.
          </p>
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-3 underline">
            Personal Data We Collect
          </h3>

          <p>
            We collect personal data when users perform certain functions on our
            site, such as registering an account or making a reservation. This
            data may include email addresses, phone numbers, and travel
            information's.
          </p>
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-3 underline">
            Use of Collected Data
          </h3>

          <p>
            We only use personal data for the purposes specified in this policy
            or indicated on relevant pages of our site. This may include
            maintaining client files and providing services to users.
          </p>
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-3 underline">
            Who We Share Personal Data With
          </h3>

          <p>
            We may share user data with employees who require it to fulfill the
            purposes outlined in this policy. Additionally, we may share data
            with third parties for specific purposes, such as payment
            processing.
          </p>
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-3 underline">
            Data Security
          </h3>

          <p>
            We take reasonable precautions to protect user data, but cannot
            guarantee complete security due to inherent risks associated with
            the internet. We request minimum information necessary to verify the
            identity of our customers.
          </p>
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-3 underline">Minors</h3>

          <p>
            Minors under the age of 15 require consent from a legal
            representative for their data to be collected, processed, and used
            in accordance with the GDPR.
          </p>
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-3 underline">User Rights</h3>

          <p>
            Under the GDPR, users have rights including access, rectification,
            erasure, and objection to their personal data. Users can contact our
            Privacy Officer to exercise these rights.
          </p>
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-3 underline">
            Modifications
          </h3>

          <p>
            This Privacy Policy may be amended to maintain compliance with the
            law and reflect changes to our data collection process. Users are
            encouraged to check the policy periodically for updates.
          </p>
        </div>

        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-3 underline">Contact</h3>

          <p>
            If you have any questions or concerns regarding our privacy
            practices, please contact us using the following:
          </p>

          <div className="mt-3">
            <div className="flex gap-2">
              Email:{' '}
              <a
                href="mailto:contact@mydisneytransfer.com"
                className="text-violet-700 dark:text-violet-400"
              >
                contact@mydisneytransfer.com
              </a>
            </div>

            <div className="flex gap-2">
              Address:{' '}
              <p className="text-violet-700 dark:text-violet-400">
                14 Rue Jean Missout, 93430 Villetaneuse France.
              </p>
            </div>

            <div className="flex gap-2">
              Tel:{' '}
              <a
                href="tel:+33652525577"
                className="text-violet-700 dark:text-violet-400"
              >
                +33652525577
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
