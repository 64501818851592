import React, { Fragment, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { FC } from 'react';
import ClearDataButton from './ClearDataButton';
import { UserPlusIcon } from '@heroicons/react/24/outline';
import { GuestsObject } from './type';
import NcInputNumber from 'components/NcInputNumber/NcInputNumber';
import { AiOutlineDown } from 'react-icons/ai';

export interface GuestsInputProps {
  fieldClassName?: string;
  className?: string;
  hasButtonSubmit?: boolean;
  buttonSubmitHref?: string;
  totalGuests?: number;
  setTotalGuests?: any;
  guestAdultsInputValue?: any;
  setGuestAdultsInputValue?: any;
  guestChildrenInputValue?: any;
  setGuestChildrenInputValue?: any;
  babySeatInputValue?: any;
  setBabySeatInputValue?: any;
  babyBoosterInputValue?: any;
  setBabyBoosterInputValue?: any;
}

const GuestsInput: FC<GuestsInputProps> = ({
  fieldClassName = '[ nc-hero-field-padding ]',
  className = '[ nc-flex-1 ]',
  hasButtonSubmit = true,
  buttonSubmitHref,
  totalGuests,
  setTotalGuests,
  guestAdultsInputValue,
  setGuestAdultsInputValue,
  guestChildrenInputValue,
  setGuestChildrenInputValue,
  babySeatInputValue,
  setBabySeatInputValue,
  babyBoosterInputValue,
  setBabyBoosterInputValue,
}) => {

  useEffect(() => {
    const _totalGuests = guestChildrenInputValue + guestAdultsInputValue;
    setTotalGuests(_totalGuests);
  }, [guestAdultsInputValue, guestChildrenInputValue]);

  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    let newValue = {
      guestAdults: guestAdultsInputValue,
      guestChildren: guestChildrenInputValue,
      babySeat: babySeatInputValue,
      babyBooster: babyBoosterInputValue,
    };
    if (type === 'guestAdults') {
      setGuestAdultsInputValue(value);
      newValue.guestAdults = value;
    }
    if (type === 'guestChildren') {
      setGuestChildrenInputValue(value);
      newValue.guestChildren = value;
    }
    if (type === 'babySeat') {
      setBabySeatInputValue(value);
      newValue.babySeat = value;
    }
    if (type === 'babyBooster') {
      setBabyBoosterInputValue(value);
      newValue.babyBooster = value;
    }
  };

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 z-10 flex items-center focus:outline-none ${
              open ? 'nc-hero-field-focused' : ''
            }`}
          >
            <Popover.Button
              className={`relative z-10 flex-1 flex text-left items-center ${fieldClassName} space-x-3 focus:outline-none`}
              onClickCapture={() => document.querySelector('html')?.click()}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
              </div>
              <div className="flex-grow">
                {totalGuests ? (
                  <span className="block xl:text-lg font-semibold dark:text-white text-black ml-3">
                    {totalGuests} Guests
                  </span>
                ) : (
                  <div className="flex justify-between">
                    <div className="block xl:text-lg font-semibold text-gray-500 ml-3">
                      Guests
                    </div>
                    <div>
                      <AiOutlineDown className='mr-2 dark:text-slate-600 cursor-pointer' />
                    </div>
                  </div>
                )}
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light ml-3">
                  {totalGuests ? 'Guests' : 'Add guests'}
                </span>
              </div>

              {!!totalGuests && (
                <ClearDataButton
                  onClick={() => {
                    setGuestAdultsInputValue(0);
                    setGuestChildrenInputValue(0);
                  }}
                />
              )}
            </Popover.Button>
          </div>

          {open && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-0 bg-white dark:bg-neutral-800"></div>
          )}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <NcInputNumber
                className="w-full"
                defaultValue={guestAdultsInputValue}
                onChange={(value) => handleChangeData(value, 'guestAdults')}
                max={8}
                min={0}
                totalGuests={totalGuests}
                label="Adults"
                desc="Ages 13 or above"
              />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={guestChildrenInputValue}
                onChange={(value) => handleChangeData(value, 'guestChildren')}
                max={8}
                min={0}
                totalGuests={totalGuests}
                label="Children"
                desc="Ages 2–12"
              />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={babySeatInputValue}
                onChange={(value) => handleChangeData(value, 'babySeat')}
                max={16}
                min={0}
                label="Baby Seats"
                desc=""
              />
              <NcInputNumber
                className="w-full mt-6"
                defaultValue={babyBoosterInputValue}
                onChange={(value) => handleChangeData(value, 'babyBooster')}
                max={16}
                min={0}
                label="Baby Boosters"
                desc=""
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
