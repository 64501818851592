import React, { FC } from 'react';
import { DEMO_CAR_LISTINGS } from 'data/listings';
import { CarDataType } from 'data/types';
import StartRating from 'components/StartRating/StartRating';
import { Link } from 'react-router-dom';
import BtnLikeIcon from 'components/BtnLikeIcon/BtnLikeIcon';
import SaleOffBadge from 'components/SaleOffBadge/SaleOffBadge';
import Badge from 'shared/Badge/Badge';
import NcImage from 'shared/NcImage/NcImage';

export interface CarCardProps {
  className?: string;
  data?: CarDataType;
  size?: 'default' | 'small';
}

const DEMO_DATA: CarDataType = DEMO_CAR_LISTINGS[0];

const CarCard: FC<CarCardProps> = ({
  size = 'default',
  className = '',
  data = DEMO_DATA,
}) => {
  const { featuredImage, title, description, href, seats, luggage, babySeat } =
    data;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full rounded-2xl overflow-hidden">
        <div className="aspect-w-16 aspect-h-9 ">
          <NcImage
            containerClassName="flex items-center justify-center"
            className="w-full"
            src={featuredImage}
          />
        </div>
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" /> */}
        {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === 'default' ? 'p-5  space-y-4' : 'p-3  space-y-2'}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2
              className={`  capitalize ${
                size === 'default'
                  ? 'text-xl font-semibold'
                  : 'text-base font-medium'
              }`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          <div className="text-neutral-500 dark:text-neutral-400 text-sm">
            <span>{description}</span>
          </div>
          <div className="text-neutral-500 dark:text-neutral-400 text-sm">
            <span className="">{seats} Passengers</span>
          </div>
          <div className="text-neutral-500 dark:text-neutral-400 text-sm">
            <span className="">{luggage} Luggage’s</span>
          </div>
          {babySeat && (
            <div className="text-neutral-500 dark:text-neutral-400 text-sm">
              <span className="">Baby seat available</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-CarCard group relative border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow bg-white dark:bg-neutral-900 will-change-transform ${className}`}
      data-nc-id="CarCard"
    >
      <Link to={href} className="flex flex-col">
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default CarCard;
