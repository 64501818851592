import Hotel1Img1 from 'images/top10Hotels/1/1.jpeg';
import Hotel1Img2 from 'images/top10Hotels/1/2.jpeg';
import Hotel1Img3 from 'images/top10Hotels/1/3.jpeg';
import Hotel1Img4 from 'images/top10Hotels/1/4.jpeg';
import Hotel1Img5 from 'images/top10Hotels/1/5.jpeg';
import Hotel1Img6 from 'images/top10Hotels/1/6.jpeg';
import Hotel1Img7 from 'images/top10Hotels/1/7.jpeg';
import Hotel1Img8 from 'images/top10Hotels/1/8.jpeg';
import Hotel1Img9 from 'images/top10Hotels/1/9.jpeg';
import Hotel1Img10 from 'images/top10Hotels/1/10.jpeg';

import Hotel2Img1 from 'images/top10Hotels/2/1.jpeg';
import Hotel2Img2 from 'images/top10Hotels/2/2.jpeg';
import Hotel2Img3 from 'images/top10Hotels/2/3.jpeg';
import Hotel2Img4 from 'images/top10Hotels/2/4.jpeg';
import Hotel2Img5 from 'images/top10Hotels/2/5.jpeg';
import Hotel2Img6 from 'images/top10Hotels/2/6.jpeg';
import Hotel2Img7 from 'images/top10Hotels/2/7.jpeg';
import Hotel2Img8 from 'images/top10Hotels/2/8.jpeg';
import Hotel2Img9 from 'images/top10Hotels/2/9.jpeg';
import Hotel2Img10 from 'images/top10Hotels/2/10.jpeg';
import Hotel2Img11 from 'images/top10Hotels/2/11.jpeg';
import Hotel2Img12 from 'images/top10Hotels/2/12.jpeg';
import Hotel2Img13 from 'images/top10Hotels/2/13.jpeg';
import Hotel2Img14 from 'images/top10Hotels/2/14.jpeg';

import Hotel3Img1 from 'images/top10Hotels/3/1.jpeg';
import Hotel3Img2 from 'images/top10Hotels/3/2.jpeg';
import Hotel3Img3 from 'images/top10Hotels/3/3.webp';
import Hotel3Img4 from 'images/top10Hotels/3/4.jpeg';
import Hotel3Img5 from 'images/top10Hotels/3/5.jpeg';

import Hotel4Img1 from 'images/top10Hotels/4/1.jpeg';
import Hotel4Img2 from 'images/top10Hotels/4/2.jpeg';
import Hotel4Img3 from 'images/top10Hotels/4/3.jpeg';
import Hotel4Img4 from 'images/top10Hotels/4/4.jpeg';
import Hotel4Img5 from 'images/top10Hotels/4/5.webp';

import Hotel5Img1 from 'images/top10Hotels/5/1.jpeg';
import Hotel5Img2 from 'images/top10Hotels/5/2.jpeg';
import Hotel5Img3 from 'images/top10Hotels/5/3.jpeg';
import Hotel5Img4 from 'images/top10Hotels/5/4.jpeg';
import Hotel5Img5 from 'images/top10Hotels/5/5.jpeg';

import Hotel6Img1 from 'images/top10Hotels/6/1.avif';
import Hotel6Img2 from 'images/top10Hotels/6/2.avif';
import Hotel6Img3 from 'images/top10Hotels/6/3.webp';
import Hotel6Img4 from 'images/top10Hotels/6/4.webp';
import Hotel6Img5 from 'images/top10Hotels/6/5.avif';
import Hotel6Img6 from 'images/top10Hotels/6/6.avif';
import Hotel6Img7 from 'images/top10Hotels/6/7.avif';
import Hotel6Img8 from 'images/top10Hotels/6/8.avif';
import Hotel6Img9 from 'images/top10Hotels/6/9.avif';
import Hotel6Img10 from 'images/top10Hotels/6/10.avif';

import Hotel7Img1 from 'images/top10Hotels/7/1.webp';
import Hotel7Img2 from 'images/top10Hotels/7/2.webp';
import Hotel7Img3 from 'images/top10Hotels/7/3.webp';
import Hotel7Img4 from 'images/top10Hotels/7/4.webp';
import Hotel7Img5 from 'images/top10Hotels/7/5.webp';
import Hotel7Img6 from 'images/top10Hotels/7/6.webp';
import Hotel7Img7 from 'images/top10Hotels/7/7.webp';
import Hotel7Img8 from 'images/top10Hotels/7/8.webp';
import Hotel7Img9 from 'images/top10Hotels/7/9.webp';
import Hotel7Img10 from 'images/top10Hotels/7/10.webp';

import Hotel8Img1 from 'images/top10Hotels/8/1.jpeg';
import Hotel8Img2 from 'images/top10Hotels/8/2.jpeg';
import Hotel8Img3 from 'images/top10Hotels/8/3.jpeg';
import Hotel8Img4 from 'images/top10Hotels/8/4.jpeg';
import Hotel8Img5 from 'images/top10Hotels/8/5.jpeg';
import Hotel8Img6 from 'images/top10Hotels/8/6.jpeg';
import Hotel8Img7 from 'images/top10Hotels/8/7.jpeg';
import Hotel8Img8 from 'images/top10Hotels/8/8.jpeg';
import Hotel8Img9 from 'images/top10Hotels/8/9.jpeg';
import Hotel8Img10 from 'images/top10Hotels/8/10.jpeg';

import Hotel9Img1 from 'images/top10Hotels/9/1.jpeg';
import Hotel9Img2 from 'images/top10Hotels/9/2.jpeg';
import Hotel9Img3 from 'images/top10Hotels/9/3.jpeg';
import Hotel9Img4 from 'images/top10Hotels/9/4.jpeg';
import Hotel9Img5 from 'images/top10Hotels/9/5.jpeg';
import Hotel9Img6 from 'images/top10Hotels/9/6.jpeg';
import Hotel9Img7 from 'images/top10Hotels/9/7.jpeg';
import Hotel9Img8 from 'images/top10Hotels/9/8.jpeg';
import Hotel9Img9 from 'images/top10Hotels/9/9.jpeg';
import Hotel9Img10 from 'images/top10Hotels/9/10.jpeg';

import Hotel10Img1 from 'images/top10Hotels/10/1.webp';
import Hotel10Img2 from 'images/top10Hotels/10/2.webp';
import Hotel10Img3 from 'images/top10Hotels/10/3.webp';
import Hotel10Img4 from 'images/top10Hotels/10/4.webp';
import Hotel10Img5 from 'images/top10Hotels/10/5.webp';
import Hotel10Img6 from 'images/top10Hotels/10/6.webp';
import Hotel10Img7 from 'images/top10Hotels/10/7.webp';
import Hotel10Img8 from 'images/top10Hotels/10/8.webp';
import Hotel10Img9 from 'images/top10Hotels/10/9.webp';
import Hotel10Img10 from 'images/top10Hotels/10/10.webp';

interface ImageType {
  id: number;
  name: string;
  thumbnail: string;
  href: string;
}

interface ActivityType {
  id: number;
  activity: string;
}

interface ServicesType {
  id: number;
  service: string;
}

export interface HotelType {
  id: number;
  name: string;
  description: string[];
  metaTitle: string;
  metaDescription: string;
  images: ImageType[];
  activities: ActivityType[];
  services: ServicesType[];
}

export const hotels: HotelType[] = [
  {
    id: 1,
    name: 'Disney Hotel New York - The Art of Marvel',
    metaTitle: 'Disney Hotel New York - The Art of Marvel  | Mydisneytransfer',
    metaDescription: 'Unveiling the Marvel Art at Disney Hotel New York',
    images: [
      {
        id: 1,
        href: '#',
        name: '',
        thumbnail: Hotel1Img1,
      },
      {
        id: 2,
        href: '#',
        name: '',
        thumbnail: Hotel1Img2,
      },
      {
        id: 3,
        href: '#',
        name: '',
        thumbnail: Hotel1Img3,
      },
      {
        id: 4,
        href: '#',
        name: '',
        thumbnail: Hotel1Img4,
      },
      {
        id: 5,
        href: '#',
        name: '',
        thumbnail: Hotel1Img5,
      },
      {
        id: 6,
        href: '#',
        name: '',
        thumbnail: Hotel1Img6,
      },
      {
        id: 7,
        href: '#',
        name: '',
        thumbnail: Hotel1Img7,
      },
      {
        id: 8,
        href: '#',
        name: '',
        thumbnail: Hotel1Img8,
      },
      {
        id: 9,
        href: '#',
        name: '',
        thumbnail: Hotel1Img9,
      },
      {
        id: 10,
        href: '#',
        name: '',
        thumbnail: Hotel1Img10,
      },
    ],
    description: [
      'Close to a year after Disney Hotel New York – The Art of Marvel welcomed guests to Disneyland Paris, the hype surrounding this remarkable 4-star hotel remains vibrant. Establishing a heroic legacy as the first hotel in the world inspired by the iconic franchise, it is the ultimate destination for any budding Super Hero in search of an unforgettable adventure.',
    ],
    activities: [
      {
        id: 1,
        activity:
          'Engage in an unforgettable encounter with a Marvel Super Hero at the Super Hero Station. Secure your spot through our official mobile app for an immersive photography session!',
      },
      {
        id: 2,
        activity:
          "Let your child's imagination soar at the Marvel Design Studio, where they can become a comic book artist and craft their own masterpiece.",
      },
    ],
    services: [
      {
        id: 1,
        service: 'Extra Magic Time',
      },
      {
        id: 2,
        service: 'Free Parking',
      },
      {
        id: 3,
        service: 'Free Wi-Fi',
      },
      {
        id: 4,
        service: 'Free Shuttle to the Parks',
      },
      {
        id: 5,
        service: 'Free Luggage Service',
      },
      {
        id: 6,
        service: 'Concierge',
      },
      {
        id: 7,
        service: 'Magic Pass',
      },
      {
        id: 8,
        service: 'Shopping Delivery Service',
      },
      {
        id: 9,
        service: 'Disabled Guest Access',
      },
      {
        id: 10,
        service: 'Free Safety Deposit Box',
      },
      {
        id: 11,
        service: 'Disney & International TV Channels',
      },
      {
        id: 12,
        service: 'PressReader',
      },
      {
        id: 13,
        service: 'Valet Parking (surcharge)',
      },
      {
        id: 14,
        service: 'Room Service (surcharge)',
      },
      {
        id: 15,
        service: 'Dry Cleaning (surcharge)',
      },
      {
        id: 16,
        service: 'Air conditioning',
      },
    ],
  },
  {
    id: 2,
    name: 'Disney Newport Bay Club',
    metaTitle: 'Disney Newport Bay Club  | Mydisneytransfer',
    metaDescription: 'Discover Disney Newport Bay Club',
    images: [
      {
        id: 1,
        href: '#',
        name: '',
        thumbnail: Hotel2Img1,
      },
      {
        id: 2,
        href: '#',
        name: '',
        thumbnail: Hotel2Img2,
      },
      {
        id: 3,
        href: '#',
        name: '',
        thumbnail: Hotel2Img3,
      },
      {
        id: 4,
        href: '#',
        name: '',
        thumbnail: Hotel2Img4,
      },
      {
        id: 5,
        href: '#',
        name: '',
        thumbnail: Hotel2Img5,
      },
      {
        id: 6,
        href: '#',
        name: '',
        thumbnail: Hotel2Img6,
      },
      {
        id: 7,
        href: '#',
        name: '',
        thumbnail: Hotel2Img7,
      },
      {
        id: 8,
        href: '#',
        name: '',
        thumbnail: Hotel2Img8,
      },
      {
        id: 9,
        href: '#',
        name: '',
        thumbnail: Hotel2Img9,
      },
      {
        id: 10,
        href: '#',
        name: '',
        thumbnail: Hotel2Img10,
      },
      {
        id: 11,
        href: '#',
        name: '',
        thumbnail: Hotel2Img11,
      },
      {
        id: 12,
        href: '#',
        name: '',
        thumbnail: Hotel2Img12,
      },
      {
        id: 13,
        href: '#',
        name: '',
        thumbnail: Hotel2Img13,
      },
      {
        id: 14,
        href: '#',
        name: '',
        thumbnail: Hotel2Img14,
      },
    ],
    description: [
      'Attention to all sea lovers - let the iconic lighthouse of Disney Newport Bay Club lead you and your group towards a 4-star experience where a relaxed elegance awaits you at every corner. This coastal mansion is adorned with charming 1920s nautical details and located on the shores of Lake Disney.',
    ],
    activities: [
      {
        id: 1,
        activity: 'Enjoy the full-service gym anytime.',
      },
      {
        id: 2,
        activity: 'Unwind with a sauna and other facilities.',
      },
    ],
    services: [
      {
        id: 1,
        service: 'Extra Magic Time',
      },
      {
        id: 2,
        service: 'Free Parking',
      },
      {
        id: 3,
        service: 'Free Wi-Fi',
      },
      {
        id: 4,
        service: 'Free Shuttle to the Parks',
      },
      {
        id: 5,
        service: 'Free Luggage Service',
      },
      {
        id: 6,
        service: 'Concierge',
      },
      {
        id: 7,
        service: 'Magic Pass',
      },
      {
        id: 8,
        service: 'Shopping Delivery Service',
      },
      {
        id: 9,
        service: 'Disabled Guest Access',
      },
      {
        id: 10,
        service: 'Free Safety Deposit Box',
      },
      {
        id: 11,
        service: 'Disney & International TV Channels',
      },
      {
        id: 12,
        service: 'PressReader',
      },
      {
        id: 13,
        service: 'Valet Parking (surcharge)',
      },
      {
        id: 14,
        service: 'Room Service (surcharge)',
      },
      {
        id: 15,
        service: 'Dry Cleaning (surcharge)',
      },
      {
        id: 16,
        service: 'Air conditioning',
      },
    ],
  },
  {
    id: 3,
    name: 'Disney Hotel Sequoia Lodge',
    metaTitle:
      'Discover the Tranquil Charm of Disney Hotel Sequoia Lodge | Mydisneytransfer',
    metaDescription:
      'Want to Explore the Beauty of Nature? Discover Disney Hotel Sequoia Lodge',
    images: [
      {
        id: 1,
        href: '#',
        name: '',
        thumbnail: Hotel3Img1,
      },
      {
        id: 2,
        href: '#',
        name: '',
        thumbnail: Hotel3Img2,
      },
      {
        id: 3,
        href: '#',
        name: '',
        thumbnail: Hotel3Img3,
      },
      {
        id: 4,
        href: '#',
        name: '',
        thumbnail: Hotel3Img4,
      },
      {
        id: 5,
        href: '#',
        name: '',
        thumbnail: Hotel3Img5,
      },
    ],
    description: [
      'Escape to the peaceful atmosphere of a tranquil forest sanctuary, inspired by the beauty of American National Parks. Relax in a cozy lodge surrounded by lush pines and towering sequoias, just a short walk from Lake Disney and the enchanting Disney Parks. Enjoy the charming Bambi-themed rooms and immerse yourself in the serene ambiance of this idyllic retreat, only 15 minutes away from the magic of Disney.',
    ],
    activities: [
      {
        id: 1,
        activity:
          'Interact with beloved Disney Characters and create unforgettable memories during your stay at the hotel.',
      },
      {
        id: 2,
        activity:
          'Enjoy a refreshing swim at the Quarry Pool, whether indoors or outdoors! With water games and slides, visitors of all ages can have a great time at the 2 heated pools set in a picturesque natural environment.',
      },
    ],
    services: [
      {
        id: 1,
        service: 'Extra Magic Time',
      },
      {
        id: 2,
        service: 'Free Parking',
      },
      {
        id: 3,
        service: 'Free Wi-Fi',
      },
      {
        id: 4,
        service: 'Free Shuttle to the Parks',
      },
      {
        id: 5,
        service: 'Free Luggage Service',
      },
      {
        id: 6,
        service: 'Concierge',
      },
      {
        id: 7,
        service: 'Magic Pass',
      },
      {
        id: 8,
        service: 'Shopping Delivery Service',
      },
      {
        id: 9,
        service: 'Disabled Guest Access',
      },
      {
        id: 10,
        service: 'Free Safety Deposit Box',
      },
      {
        id: 11,
        service: 'Disney & International TV Channels',
      },
      {
        id: 12,
        service: 'PressReader',
      },
      {
        id: 13,
        service: 'Air conditioning',
      },
    ],
  },
  {
    id: 4,
    name: 'Disney Hotel Cheyenne',
    metaTitle:
      'Toy Story Characters at Disney Hotel Cheyenne| Mydisneytransfer',
    metaDescription: 'Unforgettable Encounters: Woody or Jessie at the Lobby',
    images: [
      {
        id: 1,
        href: '#',
        name: '',
        thumbnail: Hotel4Img1,
      },
      {
        id: 2,
        href: '#',
        name: '',
        thumbnail: Hotel4Img2,
      },
      {
        id: 3,
        href: '#',
        name: '',
        thumbnail: Hotel4Img3,
      },
      {
        id: 4,
        href: '#',
        name: '',
        thumbnail: Hotel4Img4,
      },
      {
        id: 5,
        href: '#',
        name: '',
        thumbnail: Hotel4Img5,
      },
    ],
    description: [
      "Discover the enchanting Wild West atmosphere of Hotel Cheyenne, inspired by Disney's Frontier. Looking for a fantastic deal? Your search ends here! Indulge in the Wild West vibes with themed rooms featuring Disney and Pixar's Toy Story. Experience exceptional value with Disney at this vibrant hotel. Just a short 20-minute walk from the Disney Parks, Hotel Cheyenne is the ideal starting point for your unforgettable journey.",
    ],
    activities: [
      {
        id: 1,
        activity: 'Experience a Character Interaction with Woody or Jessie.',
      },
      {
        id: 2,
        activity:
          'Stroll through the lobby and meet the iconic Toy Story Characters. Take a memorable photo that will last a lifetime!',
      },
      {
        id: 3,
        activity:
          "Experience the enchanting western atmosphere of our remarkable General Store. Discover a wide range of Disney souvenirs, toys, treats, and surprises. Don't forget to make use of our convenient Disney PhotoPass™ service.",
      },
    ],
    services: [
      {
        id: 1,
        service: 'Extra Magic Time',
      },
      {
        id: 2,
        service: 'Free Parking',
      },
      {
        id: 3,
        service: 'Free Wi-Fi',
      },
      {
        id: 4,
        service: 'Free Shuttle to the Parks',
      },
      {
        id: 5,
        service: 'Free Luggage Service',
      },
      {
        id: 6,
        service: 'Concierge',
      },
      {
        id: 7,
        service: 'Magic Pass',
      },
      {
        id: 8,
        service: 'Shopping Delivery Service',
      },
      {
        id: 9,
        service: 'Disabled Guest Access',
      },
      {
        id: 10,
        service: 'Free Safety Deposit Box',
      },
      {
        id: 11,
        service: 'Disney & International TV Channels',
      },
      {
        id: 12,
        service: 'PressReader',
      },
      {
        id: 13,
        service: 'Laundromat (surcharge)',
      },
      {
        id: 14,
        service: 'Air conditioning',
      },
    ],
  },
  {
    id: 5,
    name: 'Disney Hotel Santa Fe',
    metaTitle: 'Disney Hotel Santa Fe on a Budget| Mydisneytransfer',
    metaDescription: 'Embrace the Route 66 Vibes at Disney Hotel Santa Fe',
    images: [
      {
        id: 1,
        href: '#',
        name: '',
        thumbnail: Hotel5Img1,
      },
      {
        id: 2,
        href: '#',
        name: '',
        thumbnail: Hotel5Img2,
      },
      {
        id: 3,
        href: '#',
        name: '',
        thumbnail: Hotel5Img3,
      },
      {
        id: 4,
        href: '#',
        name: '',
        thumbnail: Hotel5Img4,
      },
      {
        id: 5,
        href: '#',
        name: '',
        thumbnail: Hotel5Img5,
      },
    ],
    description: [
      "Immerse yourself in the captivating universe of Disney and Pixar's Cars at Disney Hotel Santa Fe, a lively homage to the iconic Route 66. Designed to cater to all budgets, this enchanting hotel provides a truly magical experience where you can interact with Mickey Mouse and his beloved friends.",
      "Step into a world where the spirit of adventure and the thrill of the open road come alive. Disney Hotel Santa Fe captures the essence of the beloved Cars franchise, with its vibrant decor and charming details inspired by the film. From the moment you arrive, you'll be transported to the enchanting town of Radiator Springs, where Lightning McQueen and his friends await your arrival.",
    ],
    activities: [
      {
        id: 1,
        activity:
          'Enjoy a magical moment with Mickey and friends. Meet your favorite Disney Characters in the hotel lobby every day for a memorable photo op. Strike a pose like Lightning McQueen and say: Ka-Chow!',
      },
    ],
    services: [
      {
        id: 1,
        service: 'Extra Magic Time',
      },
      {
        id: 2,
        service: 'Free Parking',
      },
      {
        id: 3,
        service: 'Free Wi-Fi',
      },
      {
        id: 4,
        service: 'Free Shuttle to the Parks',
      },
      {
        id: 5,
        service: 'Free Luggage Service',
      },
      {
        id: 6,
        service: 'Concierge',
      },
      {
        id: 7,
        service: 'Magic Pass',
      },
      {
        id: 8,
        service: 'Shopping Delivery Service',
      },
      {
        id: 9,
        service: 'Disabled Guest Access',
      },
      {
        id: 10,
        service: 'Free Safety Deposit Box',
      },
      {
        id: 11,
        service: 'Disney & International TV Channels',
      },
      {
        id: 12,
        service: 'PressReader',
      },
      {
        id: 13,
        service: 'Free Baby Cot',
      },
      {
        id: 14,
        service: 'Ceiling fan',
      },
    ],
  },
  {
    id: 6,
    name: 'Radisson Blu Hotel - Marne-la-Vallée',
    metaTitle: 'Radisson Blu Hotel - Marne-la-Vallée | Mydisneytransfer',
    metaDescription:
      'Radisson Blu Hotel  offers elegant 4-star accommodations in a peaceful natural setting',
    images: [
      {
        id: 1,
        href: '#',
        name: '',
        thumbnail: Hotel6Img1,
      },
      {
        id: 2,
        href: '#',
        name: '',
        thumbnail: Hotel6Img2,
      },
      {
        id: 3,
        href: '#',
        name: '',
        thumbnail: Hotel6Img3,
      },
      {
        id: 4,
        href: '#',
        name: '',
        thumbnail: Hotel6Img4,
      },
      {
        id: 5,
        href: '#',
        name: '',
        thumbnail: Hotel6Img5,
      },
      {
        id: 6,
        href: '#',
        name: '',
        thumbnail: Hotel6Img6,
      },
      {
        id: 7,
        href: '#',
        name: '',
        thumbnail: Hotel6Img7,
      },
      {
        id: 8,
        href: '#',
        name: '',
        thumbnail: Hotel6Img8,
      },
      {
        id: 9,
        href: '#',
        name: '',
        thumbnail: Hotel6Img9,
      },
      {
        id: 10,
        href: '#',
        name: '',
        thumbnail: Hotel6Img10,
      },
    ],
    description: [
      'The Radisson Blu Paris, Marne-la-Vallée offers elegant 4-star accommodations in a peaceful natural setting. Whether for business or leisure, their top-notch services and amenities are sure to impress. Enjoy paid parking with 20 electric car charging stations. Unwind in  indoor pool, relax by the fireplace, or participate in the "Secret de Scarlet" adventure game. With 250 non-smoking rooms and suites overlooking our lush park, our hotel is perfect for all occasions.',
      'Our team of advisors and event specialists are available to assist corporate guests, with 32 meeting spaces accommodating up to 300 attendees.',
    ],
    activities: [
      {
        id: 1,
        activity:
          'Take advantage of our indoor pool and fitness facilities to stay active during your stay.',
      },
      {
        id: 2,
        activity:
          'Enhance productivity and collaboration in a professional environment while surrounded by the tranquility of nature.',
      },
    ],
    services: [
      {
        id: 1,
        service: 'Sustainable Stays',
      },
      {
        id: 2,
        service: 'Free Parking',
      },
      {
        id: 3,
        service: 'Free Wi-Fi',
      },
      {
        id: 4,
        service: 'Free Shuttle to the Parks',
      },
      {
        id: 5,
        service: 'Free Luggage Service',
      },
      {
        id: 6,
        service: 'Concierge',
      },
      {
        id: 7,
        service: 'Magic Pass',
      },
      {
        id: 8,
        service: 'Shopping Delivery Service',
      },
      {
        id: 9,
        service: 'Air conditioning',
      },
      {
        id: 10,
        service: 'Free Safety Deposit Box',
      },
      {
        id: 11,
        service: 'Disney & International TV Channels',
      },
      {
        id: 12,
        service: 'Fitness centre',
      },
    ],
  },
  {
    id: 7,
    name: 'Vienna House Dream  Castle Hotel',
    metaTitle: 'Vienna House Dream  Castle Hotel | Mydisneytransfer',
    metaDescription: 'Stay at Vienna House Dream  Castle Hotel',
    images: [
      {
        id: 1,
        href: '#',
        name: '',
        thumbnail: Hotel7Img1,
      },
      {
        id: 2,
        href: '#',
        name: '',
        thumbnail: Hotel7Img2,
      },
      {
        id: 3,
        href: '#',
        name: '',
        thumbnail: Hotel7Img3,
      },
      {
        id: 4,
        href: '#',
        name: '',
        thumbnail: Hotel7Img4,
      },
      {
        id: 5,
        href: '#',
        name: '',
        thumbnail: Hotel7Img5,
      },
      {
        id: 6,
        href: '#',
        name: '',
        thumbnail: Hotel7Img6,
      },
      {
        id: 7,
        href: '#',
        name: '',
        thumbnail: Hotel7Img7,
      },
      {
        id: 8,
        href: '#',
        name: '',
        thumbnail: Hotel7Img8,
      },
      {
        id: 9,
        href: '#',
        name: '',
        thumbnail: Hotel7Img9,
      },
      {
        id: 10,
        href: '#',
        name: '',
        thumbnail: Hotel7Img10,
      },
    ],
    description: [
      "Escape to a world of elegance and enchantment at the Radisson Blu Paris, Marne-la-Vallée. Just minutes from Disneyland Paris, our 4-star hotel offers a magical retreat amidst sublime French-style gardens and a fairy tale palace. Experience the romance of nature's melodies as you wander through the picturesque pathways. Inside, immerse yourself in the poetic ambiance of light, gilded accents, and lush flora. Indulge in sumptuous dining, rejuvenating spa treatments, and the comfort of our meticulously designed rooms. Discover a realm where splendor and nature converge to create unforgettable moments of wonder and delight.",
    ],
    activities: [
      {
        id: 1,
        activity:
          'A little sanctuary inside your hotel, Come and relax in our well-being space, at any time of year.',
      },
      {
        id: 2,
        activity:
          'In summer, the outside pool and its water jets are a happy addition to the good weather.',
      },
    ],
    services: [
      {
        id: 1,
        service: "Family Rooms & Childrens' Equipment",
      },
      {
        id: 2,
        service: 'Free Parking',
      },
      {
        id: 3,
        service: 'Free Wi-Fi',
      },
      {
        id: 4,
        service: 'Free Shuttle to the Parks',
      },
      {
        id: 5,
        service: 'Free Luggage Service',
      },
      {
        id: 6,
        service: 'Restaurants, Bar & Terraces',
      },
      {
        id: 7,
        service: 'Magic Pass',
      },
      {
        id: 8,
        service: 'Outdoor Swimming Pool',
      },
      {
        id: 9,
        service: 'Arcade Room',
      },
      {
        id: 10,
        service: 'Free Safety Deposit Box',
      },
      {
        id: 11,
        service: 'Disney & International TV Channels',
      },
      {
        id: 12,
        service: 'French-Style Garden',
      },
      {
        id: 13,
        service: 'Well-Being Space Within an Indoor Pool & Spa',
      },
      {
        id: 14,
        service: 'Air Conditioning',
      },
    ],
  },
  {
    id: 8,
    name: 'Explorers Hotel Marne- la-Vallée',
    metaTitle: 'Explorers Hotel Marne- la-Vallée | Mydisneytransfer',
    metaDescription:
      'Explore the captivating world of pirates, explorers, and mermaids',
    images: [
      {
        id: 1,
        href: '#',
        name: '',
        thumbnail: Hotel8Img1,
      },
      {
        id: 2,
        href: '#',
        name: '',
        thumbnail: Hotel8Img2,
      },
      {
        id: 3,
        href: '#',
        name: '',
        thumbnail: Hotel8Img3,
      },
      {
        id: 4,
        href: '#',
        name: '',
        thumbnail: Hotel8Img4,
      },
      {
        id: 5,
        href: '#',
        name: '',
        thumbnail: Hotel8Img5,
      },
      {
        id: 6,
        href: '#',
        name: '',
        thumbnail: Hotel8Img6,
      },
      {
        id: 7,
        href: '#',
        name: '',
        thumbnail: Hotel8Img7,
      },
      {
        id: 8,
        href: '#',
        name: '',
        thumbnail: Hotel8Img8,
      },
      {
        id: 9,
        href: '#',
        name: '',
        thumbnail: Hotel8Img9,
      },
      {
        id: 10,
        href: '#',
        name: '',
        thumbnail: Hotel8Img10,
      },
    ],
    description: [
      "Explore the captivating world of pirates, explorers, and mermaids at the Explorers Hotel in Marne-la-Vallée! With 390 rooms, including spacious family suites, it's the ultimate destination for Disneyland® Paris enthusiasts. Delight in a 200m² water playground, thrilling 10D cinema, and diverse dining options. Enjoy added conveniences like Disney® park tickets, complimentary shuttle service, and free parking. Treat your family to an unforgettable journey filled with adventure and excitement!",
    ],
    activities: [
      {
        id: 1,
        activity:
          "Pirate Adventure Water Playground: Embark on a swashbuckling adventure at the Explorers Hotel's 200m² water playground. With thrilling slides, water cannons, and hidden treasures, little buccaneers will revel in aquatic fun fit for a pirate crew!",
      },
      {
        id: 2,
        activity:
          "Mermaid Magic 10D Cinema: Dive into a mesmerizing underwater realm with the hotel's 10D cinema experience. Watch as mermaids, sea creatures, and magical adventures come to life in stunning detail, captivating audiences of all ages with enchanting tales from the deep.",
      },
    ],
    services: [
      {
        id: 1,
        service: 'Aquatic recreation area',
      },
      {
        id: 2,
        service: 'Free Parking',
      },
      {
        id: 3,
        service: 'Free Wi-Fi',
      },
      {
        id: 4,
        service: 'Free Shuttle to the Parks',
      },
      {
        id: 5,
        service: 'Free Luggage Service',
      },
      {
        id: 6,
        service: 'Concierge',
      },
      {
        id: 7,
        service: 'Magic Pass',
      },
      {
        id: 8,
        service: 'Shopping Delivery Service',
      },
      {
        id: 9,
        service: 'Disabled Guest Access',
      },
      {
        id: 10,
        service: 'Free Safety Deposit Box',
      },
      {
        id: 11,
        service: 'Disney & International TV Channels',
      },
      {
        id: 12,
        service: 'PressReader',
      },
      {
        id: 13,
        service: 'Rooms for up to 10 people',
      },
      {
        id: 14,
        service: 'Disney tickets on sale',
      },
      {
        id: 15,
        service: 'Leisure activities within the hotel',
      },
      {
        id: 16,
        service: 'Air conditioning',
      },
    ],
  },
  {
    id: 9,
    name: 'Marriott de-France',
    metaTitle: "Marriott's Village d'Ile-de-France| Mydisneytransfer",
    metaDescription:
      "Discover Serene Luxury at Marriott's Village d'Ile-de-France Resort",
    images: [
      {
        id: 1,
        href: '#',
        name: '',
        thumbnail: Hotel9Img1,
      },
      {
        id: 2,
        href: '#',
        name: '',
        thumbnail: Hotel9Img2,
      },
      {
        id: 3,
        href: '#',
        name: '',
        thumbnail: Hotel9Img3,
      },
      {
        id: 4,
        href: '#',
        name: '',
        thumbnail: Hotel9Img4,
      },
      {
        id: 5,
        href: '#',
        name: '',
        thumbnail: Hotel9Img5,
      },
      {
        id: 6,
        href: '#',
        name: '',
        thumbnail: Hotel9Img6,
      },
      {
        id: 7,
        href: '#',
        name: '',
        thumbnail: Hotel9Img7,
      },
      {
        id: 8,
        href: '#',
        name: '',
        thumbnail: Hotel9Img8,
      },
      {
        id: 9,
        href: '#',
        name: '',
        thumbnail: Hotel9Img9,
      },
      {
        id: 10,
        href: '#',
        name: '',
        thumbnail: Hotel9Img10,
      },
    ],
    description: [
      "Nestled in the serene countryside near Disneyland Paris, Marriott's Village d'Ile-de-France resort boasts fully equipped villas accommodating up to 8 guests. Each villa features 2 to 3 bedrooms, a spacious living area, full kitchen, and private terrace with panoramic views. Guests can enjoy modern amenities such as satellite TV and complimentary internet access. The resort offers indoor and outdoor pools, a fitness center, and an on-site restaurant. Overlooking Golf Disneyland, it's also conveniently located near Val d'Europe Shopping Center and Bailly-Romainvilliers amenities, including a cinema and restaurants",
    ],
    activities: [
      {
        id: 1,
        activity:
          'Experience ultimate comfort in our 2 to 3-bedroom villas, complete with spacious living areas, full kitchens, and private terraces offering breathtaking views of the surrounding countryside.',
      },
      {
        id: 2,
        activity:
          'Indulge in relaxation with access to our indoor and outdoor pools, fitness center, and on-site restaurant. Plus, enjoy complimentary internet access and satellite TV in every villa for added convenience.',
      },
    ],
    services: [
      {
        id: 1,
        service: 'Restaurant',
      },
      {
        id: 2,
        service: 'Fitness Center',
      },
      {
        id: 3,
        service: 'Indoor Pool',
      },
      {
        id: 4,
        service: 'Outdoor Pool',
      },
      {
        id: 5,
        service: 'Hot Tub',
      },
      {
        id: 6,
        service: 'Free Wifi',
      },
      {
        id: 7,
        service: 'Game Room',
      },
      {
        id: 8,
        service: 'Activities for Kids',
      },
      {
        id: 9,
        service: 'Convenience Store',
      },
      {
        id: 10,
        service: 'Kitchen',
      },
      {
        id: 11,
        service: 'Wake up Calls',
      },
      {
        id: 12,
        service: 'Kitchenette',
      },
    ],
  },
  {
    id: 10,
    name: 'Hotel Elysée Val D Europe',
    metaTitle: 'Hotel Elysée Val D Europe | Mydisneytransfer',
    metaDescription:
      "Indulge in Unmatched Comfort at L'Elysée Val D'Europe Hotel",
    images: [
      {
        id: 1,
        href: '#',
        name: '',
        thumbnail: Hotel10Img1,
      },
      {
        id: 2,
        href: '#',
        name: '',
        thumbnail: Hotel10Img2,
      },
      {
        id: 3,
        href: '#',
        name: '',
        thumbnail: Hotel10Img3,
      },
      {
        id: 4,
        href: '#',
        name: '',
        thumbnail: Hotel10Img4,
      },
      {
        id: 5,
        href: '#',
        name: '',
        thumbnail: Hotel10Img5,
      },
      {
        id: 6,
        href: '#',
        name: '',
        thumbnail: Hotel10Img6,
      },
      {
        id: 7,
        href: '#',
        name: '',
        thumbnail: Hotel10Img7,
      },
      {
        id: 8,
        href: '#',
        name: '',
        thumbnail: Hotel10Img8,
      },
      {
        id: 9,
        href: '#',
        name: '',
        thumbnail: Hotel10Img9,
      },
      {
        id: 10,
        href: '#',
        name: '',
        thumbnail: Hotel10Img10,
      },
    ],
    description: [
      "Located just a stone's throw away from the vibrant Val D'Europe shopping center, Sea Life Aquarium, and the enchanting Disneyland Paris, L'Elysée Val D'Europe Hotel ensures a serene retreat for all guests. The well-appointed guest rooms are furnished with modern amenities, including flat-screen satellite TV and en suite facilities, promising a relaxing stay. Guests can savor exquisite French and international cuisine at the on-site restaurant or enjoy a refreshing cocktail in the peaceful garden bar. With the nearby RER Val d'Europe train station offering convenient access to Paris and ample on-site parking, L’Elysée Val D’Europe Hotel guarantees a memorable experience for all visitors.",
    ],
    activities: [
      {
        id: 1,
        activity:
          "Situated just minutes away from Val D'Europe shopping center, Sea Life Aquarium, and Disneyland Paris, our hotel offers easy access to popular attractions",
      },
      {
        id: 2,
        activity:
          'Relax in our guest rooms equipped with modern amenities such as flat-screen satellite TV and en suite facilities, ensuring a comfortable stay for all guests.',
      },
    ],
    services: [
      {
        id: 1,
        service: 'Family rooms',
      },
      {
        id: 2,
        service: 'Private parking',
      },
      {
        id: 3,
        service: 'Restaurant',
      },
      {
        id: 4,
        service: 'Bar',
      },
      {
        id: 5,
        service: 'Facilities for disabled guests',
      },
      {
        id: 6,
        service: 'Free Wifi',
      },
      {
        id: 7,
        service: 'Non-smoking rooms',
      },
      {
        id: 8,
        service: 'Room service',
      },
      {
        id: 9,
        service: 'Tea/coffee maker in all rooms',
      },
      {
        id: 10,
        service: 'Air conditioning',
      },
      {
        id: 11,
        service: 'Breakfast',
      },
    ],
  },
];
