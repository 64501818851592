import React, { FC } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { Link, useSearchParams } from 'react-router-dom';
import moment from 'moment';

export interface PayPageProps {
  className?: string;
}

const PayPage: FC<PayPageProps> = ({ className = '' }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const pickup = searchParams.get('pickup');
  const dropOff = searchParams.get('dropOff');
  const totalGuests = searchParams.get('totalGuests');
  const dropOffLocationType = searchParams.get('dropOffLocationType');
  const bookingCode = searchParams.get('reference');
  const date = searchParams.get('pickupDate');
  const time = searchParams.get('pickupTime');
  const name = searchParams.get('name');
  const price = searchParams.get('price');
  const babySeats = searchParams.get('babySeats');
  const babyBoosters = searchParams.get('babyBoosters');
  const vehicleType = searchParams.get('vehicleType');
  const pickupLocationAddress = searchParams.get('pickupLocationAddress');
  const droppingLocationAddress = searchParams.get('droppingLocationAddress');
  const returnDate = searchParams.get('returnDate');
  const returnTime = searchParams.get('returnTime');
  const comments = searchParams.get('comments');
  const email = searchParams.get('email');
  const phoneNumber = searchParams.get('phoneNumber');

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">Booking Added 🎉</h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <p className="my-3 font-bold">Dear {name},</p>

          <p>
            Your Booking request has been recorded and is confirmed. We will
            send you as soon as possible a confirmation mail by our staff.
            Please don't hesitate to contact us for any questions about your
            transfer or check our{' '}
            <Link to="/faq" className="underline font-bold">
              FAQ Page
            </Link>
            .
          </p>

          <p className="my-3">
            You can contact us via WhatsApp & Viber with number{' '}
            <a href="https://wa.me/33652525577" className="underline font-bold">
              +33 6 52 52 55 77
            </a>{' '}
            &{' '}
            <a
              href="viber://chat?number=%2B33787982321"
              className="underline font-bold"
            >
              +33 78 79 8 23 21
            </a>
            .
          </p>

          <p>Thank you for your business, see you in Paris (Disney)</p>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Your Booking details</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Name</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {name}
              </span>
            </div>

            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Email</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {email}
              </span>
            </div>

            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Phone</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                +{phoneNumber}
              </span>
            </div>

            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Booking code</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                #{bookingCode}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Date</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {date}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Time</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {time}
              </span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Vehicle Type</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {vehicleType}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Total</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {price} €
              </span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Pickup</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {pickup}
              </span>
            </div>

            {pickupLocationAddress !== 'null' && (
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Pickup Location Address</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {pickupLocationAddress}
                </span>
              </div>
            )}

            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Dropoff</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {dropOff}
              </span>
            </div>

            {droppingLocationAddress !== 'null' && (
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Dropoff Location Address</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {droppingLocationAddress}
                </span>
              </div>
            )}

            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Passengers</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {totalGuests}
              </span>
            </div>

            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Trip Type</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {dropOffLocationType === 'One' ? 'One Way' : 'Two Way'}
              </span>
            </div>

            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Baby Seats</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {babySeats}
              </span>
            </div>

            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Baby Boosters</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {babyBoosters}
              </span>
            </div>

            {returnDate !== 'null' && (
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Return Date</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {returnDate}
                </span>
              </div>
            )}

            {returnTime !== 'null' && (
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Return Time</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {returnTime}
                </span>
              </div>
            )}

            {comments !== 'null' && (
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Comments</span>
                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                  {comments}
                </span>
              </div>
            )}
          </div>
        </div>
        <div>
          <ButtonPrimary href="/">Book More</ButtonPrimary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};

export default PayPage;
