import axios from 'axios';
import { MapPinIcon } from '@heroicons/react/24/outline';
import React, { useState, useRef, useEffect, FC } from 'react';
import { Spin, Select } from 'antd';
import ClearDataButton from './ClearDataButton';
import { AiOutlineDown } from "react-icons/ai";

export interface LocationInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  setLocationId?: any;
  setLocation?: any;
  setLocationType?: any;
}

const LocationInput: FC<LocationInputProps> = ({
  autoFocus = false,
  placeHolder = 'Location',
  desc = 'Where are you going?',
  className = 'nc-flex-1.5',
  divHideVerticalLineClass = 'left-10 -right-0.5',
  setLocationId,
  setLocation,
  setLocationType,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(null);
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [loading, setLoading] = useState(false);
  const [allLocations, setAllLocations] = useState<any>([]);
  const [airports, setAirports] = useState<any>([]);
  const [stations, setStations] = useState<any>([]);
  const [disneyHotels, setDisneyHotels] = useState<any>([]);
  const [parisHotelsApartments, setParisHotelsApartments] = useState<any>([]);
  const [otherLocations, setOtherLocations] = useState<any>([]);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    try {
      setLoading(true);
      let config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_BASE_URL}/admin/viewlocation`,
      };
      const response = await axios(config);
      const locationsData = response.data;
      setAllLocations(response.data);
      const airportsData = [];
      const stationsData = [];
      const disneyHotelsData = [];
      const parisHotelsApartmentsData = [];
      const otherLocationsData = [];
      for (let index = 0; index < locationsData.length; index++) {
        const location = locationsData[index];
        if (location.Category === 'Airports') {
          let locationObject = {
            label: location.LocationName,
            value: location._id,
          };
          airportsData.push(locationObject);
        } else if (location.Category === 'Train_Stations') {
          let locationObject = {
            label: location.LocationName,
            value: location._id,
          };
          stationsData.push(locationObject);
        } else if (location.Category === 'Disney_Hotels') {
          let locationObject = {
            label: location.LocationName,
            value: location._id,
          };
          disneyHotelsData.push(locationObject);
        } else if (location.Category === 'Paris_Hotels_Apartments') {
          let locationObject = {
            label: location.LocationName,
            value: location._id,
          };
          parisHotelsApartmentsData.push(locationObject);
        } else {
          let locationObject = {
            label: location.LocationName,
            value: location._id,
          };
          otherLocationsData.push(locationObject);
        }
      }

      setAirports(airportsData);
      setStations(stationsData);
      setDisneyHotels(disneyHotelsData);
      setParisHotelsApartments(parisHotelsApartmentsData);
      setOtherLocations(otherLocationsData);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener('click', eventClickOutsideDiv);
    }
    showPopover && document.addEventListener('click', eventClickOutsideDiv);
    return () => {
      document.removeEventListener('click', eventClickOutsideDiv);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  const handleSelectLocation = (item: any) => {
    /**
     * Using the selected location, get the relevant category of the location
     */
    const location: any = allLocations.find(
      (i: { _id: any }) => i._id === item
    );
    setValue(location.LocationName);
    setLocation(location.LocationName);
    setLocationType(location.Category);
    setShowPopover(false);
  };

  const filterOption = (input: string, option: any) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? 'nc-hero-field-focused' : ''
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          {loading ? (
            <Spin size="small" />
          ) : (
            <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
          )}
        </div>

        <div className="flex-grow">
          {!value ? (
            <Select
              value={value}
              className="block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate"
              onChange={handleSelectLocation}
              showSearch
              filterOption={filterOption}
              placeholder={
                <span className="block xl:text-lg font-semibold text-gray-500">
                  Select or Search Location
                </span>
              }
              suffixIcon={<AiOutlineDown className='text-slate-600 text-[15px] cursor-pointer' />}
              dropdownStyle={{ backgroundColor: '#1F2937' }}
              options={[
                {
                  label: 'Airports',
                  options: airports,
                },
                {
                  label: 'Disneyland & Hotels',
                  options: disneyHotels,
                },
                {
                  label: 'Paris Train Stations',
                  options: stations,
                },
                {
                  label: 'Paris (Hotels, Apartments..)',
                  options: parisHotelsApartments,
                },
                {
                  label: 'Other Location',
                  options: otherLocations,
                },
              ]}
              bordered={false}
            />
          ) : (
            <div>
              <div className="flex flex-row justify-between">
                <div className="block xl:text-lg font-semibold dark:text-white text-black ml-3">
                  {value}
                </div>
                <div>
                  <ClearDataButton
                    onClick={() => {
                      setValue(null);
                      setLocationId(null);
                      setLocation(null);
                      setLocationType(null);
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          <span className="block mt-0.5 text-sm text-neutral-400 font-light ml-3">
            <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
