import React, { FC } from 'react';
import PostCardMeta from 'components/PostCardMeta/PostCardMeta';
import { PostDataType } from 'data/types';
import { Link } from 'react-router-dom';
import NcImage from 'shared/NcImage/NcImage';

export interface Card3SmallProps {
  className?: string;
  post: PostDataType;
}

const Card3Small: FC<Card3SmallProps> = ({ className = 'h-full', post }) => {
  const { title, href, desc } = post;

  return (
    <div
      className={`nc-Card3Small relative flex flex-col-reverse sm:flex-row sm:justify-between sm:items-center ${className}`}
      data-nc-id="Card3Small"
    >
      <Link to={href} className=" absolute inset-0" title={title}></Link>
      <div className="relative space-y-2">
        {/* <PostCardMeta meta={{ ...post }} /> */}
        <h2 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
          {title}
        </h2>

        <h6 className="text-neutral-400">{desc}</h6>
      </div>
    </div>
  );
};

export default Card3Small;
