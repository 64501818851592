import React from "react";
import MyRouter from "routers/index";
import { ConfigProvider, theme } from 'antd';

function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
          token: {
            colorPrimary: '#4F45E4',
          },
        }}
      >
        <MyRouter />
      </ConfigProvider>
    </div>
  );
}

export default App;
