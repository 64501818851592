import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import SectionHeroArchivePage from 'components/SectionHeroArchivePage/SectionHeroArchivePage';
import SectionSliderNewCategories from 'components/SectionSliderNewCategories/SectionSliderNewCategories';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import SectionSubscribe from 'components/SectionSubscribe2/SectionSubscribe';
import { TaxonomyType } from 'data/types';
import React, { FC } from 'react';
import SectionGridFilterCard from './SectionGridFilterCard';
import { Helmet } from 'react-helmet';
import heroRightImage from 'images/hero-right-car.png';
import SectionStatistic from 'containers/PageAbout/SectionStatistic';
import SectionStatistic2 from 'containers/PageAbout/SectionStatistic2';
import WidgetPosts from 'containers/BlogPage/WidgetPosts';
import SectionLatestPosts from 'containers/BlogPage/SectionLatestPosts';
import SectionHowItWork from 'components/SectionHowItWork/SectionHowItWork';

//
import HIW1img from 'images/howItWork/img1.png';
import HIW2img from 'images/howItWork/img2.png';
import HIW3img from 'images/howItWork/img3.png';
import HIW4img from 'images/howItWork/img4.png';
import HIW1imgDark from 'images/howItWork/img1.png';
import HIW2imgDark from 'images/howItWork/img2.png';
import HIW3imgDark from 'images/howItWork/img3.png';
import HIW4imgDark from 'images/howItWork/img4.png';
import SectionStatistic3 from 'containers/PageAbout/SectionStatistic3';

import Hotel1 from 'images/top10Hotels/1/1.jpeg';
import Hotel2 from 'images/top10Hotels/2/13.jpeg';
import Hotel3 from 'images/top10Hotels/3/1.jpeg';
import Hotel4 from 'images/top10Hotels/4/1.jpeg';
import Hotel5 from 'images/top10Hotels/5/1.jpeg';
import Hotel6 from 'images/top10Hotels/6/1.avif';
import Hotel7 from 'images/top10Hotels/7/1.webp';
import Hotel8 from 'images/top10Hotels/8/1.jpeg';
import Hotel9 from 'images/top10Hotels/9/1.jpeg';
import Hotel10 from 'images/top10Hotels/10/1.webp';

export interface ListingCarPageProps {
  className?: string;
}

const DEMO_CATS: any[] = [
  {
    id: '1',
    href: '/blog-single?hotel=1',
    name: 'Disney Hotel New York - The Art of Marvel',
    thumbnail: Hotel1,
  },
  {
    id: '2',
    href: '/blog-single?hotel=2',
    name: 'Disney Newport Bay Club',
    thumbnail: Hotel2,
  },
  {
    id: '3',
    href: '/blog-single?hotel=3',
    name: 'Disney Hotel Sequoia Lodge',
    thumbnail: Hotel3,
  },
  {
    id: '4',
    href: '/blog-single?hotel=4',
    name: 'Disney Hotel Cheyenne',
    thumbnail: Hotel4,
  },
  {
    id: '5',
    href: '/blog-single?hotel=5',
    name: 'Disney Hotel Santa Fe',
    thumbnail: Hotel5,
  },
  {
    id: '6',
    href: '/blog-single?hotel=6',
    name: 'Radisson Blu Hotel, Marne-la-Vallée',
    thumbnail: Hotel6,
  },
  {
    id: '7',
    href: '/blog-single?hotel=7',
    name: 'Vienna House Dream Castle Hotel',
    thumbnail: Hotel7,
  },
  {
    id: '8',
    href: '/blog-single?hotel=8',
    name: 'Explorers Hotel Marne-la-Vallée',
    thumbnail: Hotel8,
  },
  {
    id: '9',
    href: '/blog-single?hotel=9',
    name: "Marriott's Village d'Île-de-France",
    thumbnail: Hotel9,
  },
  {
    id: '10',
    href: '/blog-single?hotel=10',
    name: "Hotel l'Elysée Val d'Europe",
    thumbnail: Hotel10,
  },
];

const ListingCarPage: FC<ListingCarPageProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-ListingCarPage relative overflow-hidden ${className}`}
      data-nc-id="ListingCarPage"
    >
      <Helmet>
        <title>Mydisney | Private airport transfers service</title>
        <meta
          name="title"
          content="Reliable and convenient Disney Paris Airport transfer services"
        />
        <meta
          name="description"
          content="Private airport transfers service to Disneyland Paris, Paris Airport transfer, Paris City and sightseen tours in and out Paris"
        />
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          rightImage={
            'https://media.istockphoto.com/id/1145422105/photo/eiffel-tower-aerial-view-paris.jpg?s=612x612&w=0&k=20&c=sFn6FwTJR0TpX3rP_W4VHrbkTB__6l5kr-lkkqdYrtE='
          }
          currentPage="Cars"
          currentTab="Cars"
          listingType={
            <>
              <i className="text-2xl las la-car"></i>
              <span className="ml-2.5">1512 cars</span>
            </>
          }
          className="pt-5 pb-24 lg:pb-20 lg:pt-12"
        />

        {/* CARDS SECTION */}
        <div className="pb-20">
          {/* <SectionStatistic /> */}
          <SectionGridFilterCard />
        </div>

        {/* SECTION */}
        <SectionSubscribe2 className="py-24 lg:py-20" />

        {/* CARDS SECTION */}
        <div className="pb-20">
          {/* SECTION */}
          <SectionHowItWork
            data={[
              {
                id: 1,
                img: HIW3img,
                imgDark: HIW3imgDark,
                title: 'Get Price',
                desc: 'Name the area or type of home you are looking for the search bar. Our app will find you the perfect match.',
              },
              {
                id: 2,
                img: HIW4img,
                imgDark: HIW4imgDark,
                title: 'Fill the Form',
                desc: 'From the number of options our app will provide, you can select any property that you like to explore.',
              },
              {
                id: 3,
                img: HIW2img,
                imgDark: HIW2imgDark,
                title: 'Confirmation',
                desc: 'Find a home or space from our search bar. Enter your specific location, property type and price range.',
              },
              {
                id: 4,
                img: HIW1img,
                imgDark: HIW1imgDark,
                title: 'Get Ready',
                desc: 'From the number of options our app will provide, you can select any property that you like to explore.',
              },
            ]}
          />
        </div>

        <div className="pb-20">
          <WidgetPosts />
        </div>

        <div>
          <SectionLatestPosts className="pb-20" />
        </div>

        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Top 10 Hotels in Disneyland ✈"
            subHeading="Explore thousands of destinations around the world"
            categoryCardType="card4"
            itemPerRow={5}
            categories={DEMO_CATS}
            sliderStyle="style2"
            uniqueClassName="ListingCarPage"
          />
        </div>

        {/* CARDS SECTION */}
        <div className="py-20">
          <SectionStatistic2 />
        </div>

        {/* SECTION */}
        <SectionStatistic3 className="py-10" />
        {/* <SectionSubscribe className="py-24 lg:py-20" /> */}
      </div>
    </div>
  );
};

export default ListingCarPage;
