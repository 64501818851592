import React, { FC, useState } from 'react';
import ButtonCircle from 'shared/Button/ButtonCircle';
import rightImg from 'images/newsletter2.jpeg';
import NcImage from 'shared/NcImage/NcImage';
import Badge from 'shared/Badge/Badge';
import Input from 'shared/Input/Input';
import { Spin, notification } from 'antd';
import axios from 'axios';

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = '' }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const payload = {
        mail: email,
      };
      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_BASE_URL}/user/insertmail`,
        data: payload,
      };
      const response = await axios(config);
      if (response.status === 201) {
        notification['success']({
          key: 'subscribe',
          message: 'Success!',
          description: 'Subscribed to the newsletter successfully!',
        });
        setLoading(false);
        setEmail('');
      }
    } catch (error) {
      setLoading(false);
      notification['error']({
        key: 'subscribe',
        message: 'Error!',
        description: 'Error occured while subscribing!',
      });
    }
  };

  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">Join our newsletter 🎉</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          Read and share new perspectives on just about any topic. Everyone’s
          welcome.
        </span>
        <ul className="space-y-4 mt-10">
          <li className="flex items-center space-x-4">
            <Badge name="01" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get Free Rides
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge color="red" name="02" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get more discounts
            </span>
          </li>
        </ul>
        <form className="mt-10 relative max-w-sm" onSubmit={handleSubmit}>
          <Input
            required
            aria-required
            placeholder="Enter your email"
            type="email"
            rounded="rounded-full"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
          >
            {loading ? (
              <Spin size="small" />
            ) : (
              <i className="las la-arrow-right text-xl"></i>
            )}
          </ButtonCircle>
        </form>
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} className="rounded-lg" />
      </div>
    </div>
  );
};

export default SectionSubscribe2;
