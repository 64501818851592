import { FC, useState, useEffect } from 'react';
import LocationInput from '../LocationInput';
import GuestsInput from '../GuestsInput';
import { Input, Avatar, Checkbox, Button, notification, Spin } from 'antd';
import car from '../../../images/cars/icons/1.jpg';
import economyVan from '../../../images/cars/icons/2.jpg';
import businessVan from '../../../images/cars/icons/3.jpg';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export interface RentalCarSearchFormProps {}

const RentalCarSearchForm: FC<RentalCarSearchFormProps> = () => {
  const navigate = useNavigate();

  const [dropOffLocationType, setDropOffLocationType] = useState<'One' | 'Two'>(
    'One'
  );

  const [pickupLocation, setPickupLocation] = useState<any>(null);
  const [pickupLocationId, setPickupLocationId] = useState<any>(null);
  const [pickupLocationAddress, setPickupLocationAddress] = useState<any>(null);
  const [pickupLocationType, setPickupLocationType] = useState<any>(null);

  const [droppingLocation, setDroppingLocation] = useState<any>(null);
  const [droppingLocationId, setDroppingLocationId] = useState<any>(null);
  const [droppingLocationAddress, setDroppingLocationAddress] =
    useState<any>(null);
  const [droppingLocationType, setDroppingLocationType] = useState<any>(null);

  const [vehicleType, setVehicleType] = useState<string>('');
  const [isCar, setIsCar] = useState<boolean>(false);
  const [isEconomyVan, setIsEconomyVan] = useState<boolean>(false);
  const [isBusinessVan, setIsBusinessVan] = useState<boolean>(false);

  const [price, setPrice] = useState(0);
  const [priceLoading, setPriceLoading] = useState(false);
  const [totalGuests, setTotalGuests] = useState<number>(0);

  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState<number>(0);
  const [guestChildrenInputValue, setGuestChildrenInputValue] =
    useState<number>(0);
  const [babySeatInputValue, setBabySeatInputValue] = useState<number>(0);
  const [babyBoosterInputValue, setBabyBoosterInputValue] = useState<number>(0);

  useEffect(() => {
    if (totalGuests >= 5 && isCar) {
      setIsCar(false);
      setVehicleType('');
    }
  }, [totalGuests]);

  useEffect(() => {
    if (
      totalGuests &&
      pickupLocation &&
      droppingLocation &&
      dropOffLocationType &&
      vehicleType
    ) {
      fetchPrice();
    } else {
      setPrice(0);
    }
  }, [
    totalGuests,
    pickupLocation,
    droppingLocation,
    dropOffLocationType,
    vehicleType,
  ]);

  const fetchPrice = async () => {
    try {
      setPriceLoading(true);
      let config = {
        method: 'get',
        url: `${process.env.REACT_APP_API_BASE_URL}/user/getprice/${pickupLocation}/${droppingLocation}/${dropOffLocationType}/${totalGuests}/${vehicleType}`,
      };

      const response = await axios(config);
      const price = response.data.price;
      if (price) {
        setPrice(price);
      } else {
        setPrice(0);
        notification['error']({
          key: 'price_fetch',
          message: 'Sorry',
          description: 'Currently no prices have added for this trip!',
        });
      }
      setPriceLoading(false);
    } catch (error) {
      setPriceLoading(false);
      notification['error']({
        key: 'price_fetch',
        message: 'Oops!',
        description: 'Error when fetching price!',
      });
    }
  };

  const handleVehicleCheckbox = (e: any) => {
    if (e.target.name === 'isCar') {
      setIsCar(e.target.checked);
      setIsEconomyVan(false);
      setIsBusinessVan(false);
      setVehicleType('Car');
    } else if (e.target.name === 'isEconomyVan') {
      setIsEconomyVan(e.target.checked);
      setIsCar(false);
      setIsBusinessVan(false);
      setVehicleType('Economy Class Van');
    } else if (e.target.name === 'isBusinessVan') {
      setIsBusinessVan(e.target.checked);
      setIsCar(false);
      setIsEconomyVan(false);
      setVehicleType('Business Class Van');
    }
  };

  const handleSubmit = () => {
    if (
      pickupLocation &&
      droppingLocation &&
      totalGuests !== 0 &&
      price !== 0
    ) {
      navigate(
        `/checkout?pickup=${pickupLocation}&dropOff=${droppingLocation}&adults=${guestAdultsInputValue}&children=${guestChildrenInputValue}&totalGuests=${totalGuests}&babySeats=${babySeatInputValue}&babyBoosters=${babyBoosterInputValue}&dropOffLocationType=${dropOffLocationType}&pickupLocationType=${pickupLocationType}&droppingLocationType=${droppingLocationType}&pickupLocationAddress=${pickupLocationAddress}&droppingLocationAddress=${droppingLocationAddress}&vehicleType=${vehicleType}`
      );
    } else {
      notification['error']({
        key: 'proceed',
        message: 'Required Fields Missing',
        description: 'Cannot proceed further!',
      });
    }
  };

  const renderRadioBtn = () => {
    return (
      <div className=" py-5 [ nc-hero-field-padding ] flex items-center flex-wrap flex-row border-b border-neutral-100 dark:border-neutral-700">
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 sm:mr-3 ${
            dropOffLocationType === 'One'
              ? 'bg-black text-white shadow-black/10 shadow-lg'
              : 'border border-neutral-300 dark:border-neutral-700'
          }`}
          onClick={(e) => setDropOffLocationType('One')}
        >
          One Way
        </div>
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 sm:mr-3 ${
            dropOffLocationType === 'Two'
              ? 'bg-black text-white shadow-black/10 shadow-lg'
              : 'border border-neutral-300 dark:border-neutral-700'
          }`}
          onClick={(e) => setDropOffLocationType('Two')}
        >
          Two Way
        </div>
      </div>
    );
  };

  return (
    <form className="relative rounded-[40px] xl:rounded-[49px] rounded-t-2xl xl:rounded-t-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
      {renderRadioBtn()}
      <div>
        <LocationInput
          placeHolder="Pick up location"
          desc="Pick up location"
          className="flex-1"
          setLocationId={setPickupLocationId}
          setLocation={setPickupLocation}
          setLocationType={setPickupLocationType}
        />

        {((pickupLocation && pickupLocation.toLowerCase() === 'other') ||
          (pickupLocation &&
            pickupLocation ===
              'Paris (Hotels, Apartments, Historic Monuments)') ||
          (pickupLocation && pickupLocation === 'Paris Hotels & Apartments') ||
          (pickupLocation &&
            pickupLocation === 'Other Locations Near Disneyland') ||
          (pickupLocation && pickupLocation === 'Paris')) && (
          <div className="px-5">
            <Input
              placeholder="Enter Pickup Location Address"
              className="h-[50px]"
              value={pickupLocationAddress}
              onChange={(e) => setPickupLocationAddress(e.target.value)}
            />
          </div>
        )}

        <div className="self-center border-r border-slate-200 dark:border-slate-700"></div>
        <LocationInput
          placeHolder="Drop off location"
          desc="Drop off location"
          className="flex-1"
          divHideVerticalLineClass="-inset-x-0.5"
          setLocationId={setDroppingLocationId}
          setLocation={setDroppingLocation}
          setLocationType={setDroppingLocationType}
        />

        {((droppingLocation && droppingLocation.toLowerCase() === 'other') ||
          (droppingLocation &&
            droppingLocation ===
              'Paris (Hotels, Apartments, Historic Monuments)') ||
          (droppingLocation &&
            droppingLocation === 'Paris Hotels & Apartments') ||
          (droppingLocation &&
            droppingLocation === 'Other Locations Near Disneyland') ||
          (droppingLocation && droppingLocation === 'Paris')) && (
          <div className="px-5">
            <Input
              placeholder="Enter Drop Off Location Address"
              className="h-[50px]"
              value={droppingLocationAddress}
              onChange={(e) => setDroppingLocationAddress(e.target.value)}
            />
          </div>
        )}

        <div className="self-center border-r border-slate-200 dark:border-slate-700"></div>
        <GuestsInput
          totalGuests={totalGuests}
          setTotalGuests={setTotalGuests}
          guestAdultsInputValue={guestAdultsInputValue}
          setGuestAdultsInputValue={setGuestAdultsInputValue}
          guestChildrenInputValue={guestChildrenInputValue}
          setGuestChildrenInputValue={setGuestChildrenInputValue}
          babySeatInputValue={babySeatInputValue}
          setBabySeatInputValue={setBabySeatInputValue}
          babyBoosterInputValue={babyBoosterInputValue}
          setBabyBoosterInputValue={setBabyBoosterInputValue}
          className="flex-1"
        />

        {totalGuests >= 8 && (
          <div className="px-8 my-2">
            <p className="text-red-500 text-sm">
              Maximum guest limit reached. Please add another booking if you
              have additional guests.
            </p>
          </div>
        )}

        <div className="self-center border-r border-slate-200 dark:border-slate-700"></div>
        <div className="px-8 py-3">
          <h2 className="xl:text-lg font-semibold dark:text-white text-black">
            Choose Your Vehicle
          </h2>
          <div className="flex justify-between gap-3 mt-5">
            <div className="col-span-1 text-center">
              <div>
                <Avatar
                  shape="square"
                  size={80}
                  src={car}
                  className={
                    totalGuests && Number(totalGuests) >= 5
                      ? 'opacity-20'
                      : 'opacity-100'
                  }
                />
              </div>

              <div className="mt-2">
                <Checkbox
                  checked={isCar}
                  name="isCar"
                  onChange={(e) => handleVehicleCheckbox(e)}
                  disabled={
                    totalGuests && Number(totalGuests) >= 5 ? true : false
                  }
                >
                  <span className="text-black dark:text-white">Car</span>
                </Checkbox>
              </div>
            </div>

            <div className="col-span-1 text-center">
              <div>
                <Avatar shape="square" size={80} src={economyVan} />
              </div>

              <div className="mt-2">
                <Checkbox
                  checked={isEconomyVan}
                  name="isEconomyVan"
                  onChange={(e) => handleVehicleCheckbox(e)}
                >
                  <span className="text-black dark:text-white">
                    Economy Van
                  </span>
                </Checkbox>
              </div>
            </div>

            <div className="col-span-1 text-center">
              <div>
                <Avatar shape="square" size={80} src={businessVan} />
              </div>

              <div className="mt-2">
                <Checkbox
                  checked={isBusinessVan}
                  name="isBusinessVan"
                  onChange={(e) => handleVehicleCheckbox(e)}
                >
                  <span className="text-black dark:text-white">
                    Business Van
                  </span>
                </Checkbox>
              </div>
            </div>
          </div>
        </div>

        <div className="p-5">
          <Button
            type="primary"
            size="large"
            className="bg-primary-500 w-full"
            style={{ borderRadius: '20px' }}
            onClick={() => handleSubmit()}
            disabled={priceLoading || price === 0}
          >
            <div className="flex flex-row justify-center gap-2 text-black dark:text-white">
              <div>Book Now</div>
              {priceLoading ? (
                <div>
                  <Spin size="small" />
                </div>
              ) : (
                <div>({price} €)</div>
              )}
            </div>
          </Button>
        </div>
      </div>
    </form>
  );
};

export default RentalCarSearchForm;
