import React, { FC } from 'react';
import Logo from 'shared/Logo/Logo';
import Navigation from 'shared/Navigation/Navigation';
import SearchDropdown from './SearchDropdown';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import MenuBar from 'shared/MenuBar/MenuBar';
import SwitchDarkMode from 'shared/SwitchDarkMode/SwitchDarkMode';
import HeroSearchForm2MobileFactory from 'components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory';

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = '' }) => {
  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-1 relative flex justify-between items-center">
        <div className="md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
        </div>

        <div className="flex justify-center items-center">
          <Navigation />
        </div>

        <div className="md:flex flex-shrink-0 items-center justify-end lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            <SwitchDarkMode />
            <div className="px-1" />
          </div>
          <div className="flex xl:hidden items-center">
            <SwitchDarkMode />
            <div className="px-0.5" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
