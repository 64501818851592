import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import SocialsList from 'shared/SocialsList/SocialsList';
import Label from 'components/Label/Label';
import Input from 'shared/Input/Input';
import Textarea from 'shared/Textarea/Textarea';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import SectionClientSay from 'components/SectionClientSay/SectionClientSay';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import GoogleMapReact from 'google-map-react';
import NcImage from 'shared/NcImage/NcImage';
import ContactUsImg from 'images/contact.png';
import LocationMarker from 'components/AnyReactComponent/LocationMarker';
import { IoLogoWhatsapp } from 'react-icons/io';

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: '🗺 ADDRESS',
    desc: '14 Rue Jean Missout 93430 Villetaneuse France.',
  },
  {
    title: '💌 EMAIL',
    desc: 'contact@mydisneytransfer.com',
  },
  {
    title: '☎ PHONE - From outside France',
    desc: '+ 33 6 52 52 55 77',
  },
  {
    title: '☎ PHONE - Inside France',
    desc: '06 52 52 55 77 / + 33 7 87 98 23 21',
  },
];

const PageContact: FC<PageContactProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Mydisney Contact Transfer Service</title>
        <meta
          name="title"
          content="Reach out to our team for comprehensive details"
        />
        <meta
          name="description"
          content="Contact our team for detailed information and assistance with your transfer request before your arrival. Get in touch now!"
        />
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-10 sm:my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact Us
        </h2>
        <div className="container max-w-7xl mx-auto my-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8 my-auto">
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🗺 ADDRESS
                </h3>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  14 Rue Jean Missout 93430 Villetaneuse France.
                </span>
              </div>

              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  💌 EMAIL
                </h3>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  <a href="mailto:contact@mydisneytransfer.com">
                    contact@mydisneytransfer.com
                  </a>
                </span>
              </div>

              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  ☎ PHONE - From outside France
                </h3>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  <a href="tel:+33652525577">+33 6 52 52 55 77</a>
                </span>
              </div>

              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  ☎ PHONE - Inside France
                </h3>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  <a href="tel:0652525577">06 52 52 55 77</a> /{' '}
                  <a href="tel:+33787982321">+33 7 87 98 23 21</a>
                </span>
              </div>

              <div>
                <div className="flex gap-2 uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  <div className="my-auto text-[#25D366]">
                    <IoLogoWhatsapp />
                  </div>{' '}
                  <div className="my-auto">
                    <h3>Whatsapp</h3>
                  </div>
                </div>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  <a href="https://wa.me/+33652525577">+33 6 52 52 55 77</a>
                </span>
              </div>
              {/* {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))} */}
              {/* <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div> */}
            </div>
            <div className="my-auto">
              {/* <form className="grid grid-cols-1 gap-6" action="#" method="post">
                <label className="block">
                  <Label>Full name</Label>

                  <Input
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>Email address</Label>

                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>

                  <Textarea className="mt-1" rows={6} />
                </label>
                <div>
                  <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                </div>
              </form> */}
              <NcImage src={ContactUsImg} className="rounded-lg" />
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      <div className="container">
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="Pagecontact_" />
        </div> */}

        <div className="mt-4">
          <div className="overflow-hidden">
            {/* <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY',
                }}
                yesIWantToUseGoogleMapApiInternals
                defaultZoom={15}
                defaultCenter={{
                  lat: 48.924520313123544,
                  lng: 2.4054791846585215,
                }}
              >
                <LocationMarker
                  lat={48.924520313123544}
                  lng={2.4054791846585215}
                />
              </GoogleMapReact> */}

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2619.2258885602873!2d2.3412132764562883!3d48.968224292970966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6690fea6049b5%3A0x24c236c07cd31f9b!2s14%20Rue%20Jean%20Missout%2C%2093430%20Villetaneuse%2C%20France!5e0!3m2!1sen!2slk!4v1711940231020!5m2!1sen!2slk"
              width="600"
              height="450"
              loading="lazy"
              allowFullScreen
              className="w-full rounded-xl"
            />
          </div>
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div>
    </div>
  );
};

export default PageContact;
